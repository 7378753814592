import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import { ref, set, update } from "firebase/database";
import { database } from "../firebase";
import helpIcon from '../assets/help.svg'
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';
import "./Bfutton.css";

const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function MlPageEnergy() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2) // Convert object to a readable string format
        };

        emailjs.send(
            SERVICE_ID,      // Replace with your EmailJS service ID
            TEMPLATE_ID,     // Replace with your EmailJS template ID
            emailParams,            // Parameters to send (like finalIotObject)
            PUBLIC_KEY          // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const [changesAdded, setChangesAdded] = useState(false);
    const [otherDetails, setOtherDetails] = useState(() => {
        const savedOtherDetails = sessionStorage.getItem('iototherdetails');
        return savedOtherDetails ? JSON.parse(savedOtherDetails) : {
            mlModedNeed: 'No',
            energyNeed: 'No',
            mlIntegrate: '',
            mlDescription: '',
            energyDetails: '',
            batteryNeed: 'AC power'
        }
    });

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
            // if (!changesAdded) {
            //     // Optionally add a warning for unsaved changes
            //     event.preventDefault(); // For most browsers
            //     event.returnValue = '';  // For Chrome
            // }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('iototherdetails', JSON.stringify(otherDetails));
    }, [otherDetails])

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setOtherDetails((prev) => ({ ...prev, [name]: value }));

        if (name === 'mlModedNeed' && value === 'No') {
            setOtherDetails((prev) => ({ ...prev, mlDescription: '', mlIntegrate: '' }));
        }
        if (name === 'energyNeed' && value === 'No') {
            setOtherDetails((prev) => ({ ...prev, energyDetails: '' }));
        }
    }
    const [errors, setError] = useState(
        {
            mlDescription: false,
            // energyDetails: false,
            mlIntegrate: false
        }
    );

    const [finishButtonText,setFinishButtonText]=useState('Next');

    useEffect(()=>{
        const enclosureSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).enclosure;
        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));
        if (componentSelected === 'Complete System') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";

            if (otherDetails.batteryNeed === 'Battery' || otherDetails.batteryNeed === 'AC power with backup battery') {
                
            } else if (enclosureSelected === 'Yes') {
                
            } else if (pcbSelected === 'Yes') {
               
            } else if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                   
                } else if (interfaceSelected === 'Web App') {
                   
                } else if (interfaceSelected === 'Touch Display') {
                    
                } else if (interfaceSelected === 'Non-Touch Display') {
                    
                }
            } else {
                setFinishButtonText('Finish');
            }

        } else {
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));

            if (otherDetails.batteryNeed === 'Battery' || otherDetails.batteryNeed === 'AC power with backup battery') {
                
            } else if (enclosureSelected === 'Yes') {
                
            } else if (pcbSelected === 'Yes') {
                
            } else if (interfaceSelected === 'Mobile App') {
               
            } else if (interfaceSelected === 'Web App') {
                
            } else if (interfaceSelected === 'Touch Display') {
               
            } else if (interfaceSelected === 'Non-Touch Display') {
               
            } else {
                setFinishButtonText("Finish");
            }

        }
    },[]);

    function handleClickNext() {

        let newError = {};

        if (otherDetails.mlModedNeed === 'Yes') {
            if (!otherDetails.mlIntegrate) {
                newError.mlIntegrate = true;

            } else {
                newError.mlIntegrate = false;
            }
        }

        setError(newError);

        if (Object.values(newError).some((error) => error)) {
            return;
        }

        console.log(JSON.parse(sessionStorage.getItem('iototherdetails')));
        const enclosureSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).enclosure;
        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));
        console.log(JSON.parse('1212'));

        if (componentSelected === 'Complete System') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";

            if (otherDetails.batteryNeed === 'Battery' || otherDetails.batteryNeed === 'AC power with backup battery') {
                navigate('/batterypage');
            } else if (enclosureSelected === 'Yes') {
                navigate('/enclosuredesignpage');
            } else if (pcbSelected === 'Yes') {
                navigate('/pcbdesignpage_1');
            } else if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                    navigate('/mobileapppage');
                } else if (interfaceSelected === 'Web App') {
                    navigate('/webapplicationpage');
                } else if (interfaceSelected === 'Touch Display') {
                    navigate('/touchdisplaypage');
                } else if (interfaceSelected === 'Non-Touch Display') {
                    navigate('/nontouchdisplaypage');
                }
            } else {

                const finalIotObject = {
                    iot_page_1: iot1,
                    iot_page_2: iot2,
                    iot_page_3: iot3,
                    iot_page_4: iot4,
                    iot_page_5: iot5,
                    ML_Energyconservation_Battery: otherDetails
                };

                let permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
                const iotDeviceKeyName = `iot_device_${permanentdetails.numberOfIotDevices}`
                let detailsSaved = {}

                let dateNow = 'date';
                if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                    dateNow = Date.now();

                    sessionStorage.setItem('permanentdetails', JSON.stringify(
                        {
                            ...permanentdetails,
                            dateNow: dateNow,
                        }
                    ));
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    detailsSaved = {
                        personalDetails: personDetailsSaved,
                        component: componentSelected,
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    set(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");

                        if (numberOfIotDevicesTotal === '1' || permanentdetails.numberOfIotDevices === '1') {



                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();

                            // setTimeout(() => {
                            //     sessionStorage.clear();
                            //     window.location.href = 'https://protonest.co/';
                            // }, 100);
                        } else {

                            permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));

                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );
                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');

                            sessionStorage.removeItem('uiselected');

                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })
                } else {
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    dateNow = permanentdetails.dateNow;
                    detailsSaved = {
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    update(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === 1 || permanentdetails.numberOfIotDevices === 1) {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();

                            // setTimeout(() => {
                            //     sessionStorage.clear();
                            //     window.location.href = 'https://protonest.co/';
                            // }, 100);
                        } else {
                            permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );
                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('uiselected');

                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })
                }


            }

        } else {
            console.log("this is it");

            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));

            if (otherDetails.batteryNeed === 'Battery' || otherDetails.batteryNeed === 'AC power with backup battery') {
                navigate('/batterypage');
            } else if (enclosureSelected === 'Yes') {
                navigate('/enclosuredesignpage');
            } else if (pcbSelected === 'Yes') {
                navigate('/pcbdesignpage_1');
            } else if (interfaceSelected === 'Mobile App') {
                navigate('/mobileapppage');
            } else if (interfaceSelected === 'Web App') {
                navigate('/webapplicationpage');
            } else if (interfaceSelected === 'Touch Display') {
                navigate('/touchdisplaypage');
            } else if (interfaceSelected === 'Non-Touch Display') {
                navigate('/nontouchdisplaypage');
            } else {
                const finalIotObject = {
                    iot_page_1: iot1,
                    iot_page_2: iot2,
                    iot_page_3: iot3,
                    iot_page_4: iot4,
                    iot_page_5: iot5,
                    ML_Energyconservation_Battery: otherDetails
                };
                const detailsSaved = {
                    personalDetails: personDetailsSaved,
                    component: componentSelected,
                    details: finalIotObject
                }
                const dbRef = ref(database, `${Date.now()}`);
                set(dbRef, detailsSaved).then(() => {
                    console.log("Saved to database");
                }).catch((error) => {
                    console.error("Error saving in database: ", error);
                })

                sendEmail(detailsSaved);
                sessionStorage.clear();
                setChangesAdded(true);
                handleClickOpen();

                // setTimeout(() => {
                //     sessionStorage.clear();
                //     window.location.href = 'https://protonest.co/';
                // }, 100);
            }

        }

    }

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '1rem',
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={6} >

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>Other details (ML, Energy conservation & Battery)</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="margin" >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        ML Model/API needed? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Yes", "No"].map((mlModedNeed) => (
                                            <label className="text" key={mlModedNeed} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={mlModedNeed}
                                                    name="mlModedNeed"
                                                    checked={otherDetails.mlModedNeed === mlModedNeed}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {mlModedNeed}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="margin">
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        What is the source of powering the device? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Battery", "AC power", "AC power with backup battery"].map((batteryNeed) => (
                                            <label className="text" key={batteryNeed} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={batteryNeed}
                                                    name="batteryNeed"
                                                    checked={otherDetails.batteryNeed === batteryNeed}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {batteryNeed}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Should the energy consumption be minimized? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Yes", "No"].map((energyNeed) => (
                                            <label className="text" key={energyNeed} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={energyNeed}
                                                    name="energyNeed"
                                                    checked={otherDetails.energyNeed === energyNeed}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {energyNeed}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col sm={12} md={6} >
                        {otherDetails.mlModedNeed === 'Yes' &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div className="margin10px" >
                                        <label className="text" style={{ fontFamily: 'poppins', color: errors.mlIntegrate ? "red" : "rgba(71, 85, 105, 1)" }}>
                                            ML Model located in? <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {["Embedded System", "Mobile App"].map((mlIntegrate) => (
                                                <label className="text" key={mlIntegrate} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={mlIntegrate}
                                                        name="mlIntegrate"
                                                        checked={otherDetails.mlIntegrate === mlIntegrate}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {mlIntegrate}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {otherDetails.mlModedNeed === 'Yes' &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginTop: '1rem' }} >
                                        <label
                                            className="text"
                                            style={{
                                                fontWeight: '400',
                                                marginBottom: "0.5rem",

                                                fontFamily: "poppins",
                                                color: "rgba(71, 85, 105, 1)",
                                            }}
                                        >
                                            Description about the ML model 
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer"><img src={helpIcon} alt="help" style={{
                                                paddingLeft: '3px', width: '1rem',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span>
                                        </label>
                                        <br />
                                        <textarea
                                            type="text"
                                            name="mlDescription"
                                            placeholder="Enter description"
                                            value={otherDetails.mlDescription}
                                            className="textBox4"
                                            style={{
                                                border: errors.mlDescription ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',

                                                padding: '3px 3px 3px 15px',
                                                marginTop: "0.3rem",
                                                maxLines: '20',
                                                borderRadius: "5px",

                                                width: "90%",
                                                resize: 'none'
                                            }}
                                            onChange={(e) => (setOtherDetails((prev) => { return { ...prev, mlDescription: e.target.value } }))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                        {otherDetails.energyNeed === 'Yes' &&

                            <Row style={{ marginTop: '1rem' }} >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginTop: '1rem', paddingBottom: "10px", }} >
                                        <label
                                            className="text"
                                            style={{
                                                fontWeight: '400',
                                                marginBottom: "0.5rem",
                                                fontFamily: "poppins",
                                                color: "rgba(71, 85, 105, 1)",
                                            }}
                                        >
                                            What is the ratio for having energy consumption: price (The unit price usually increases if we
                                            use hardware base power-optimizing strategies) We will use firmware base optimizations.
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer"><img src={helpIcon} alt="help" style={{
                                                paddingLeft: '3px', width: '1rem',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span>
                                        </label>
                                        <br />
                                        <textarea
                                            type="text"
                                            name="energyDetails"
                                            placeholder="Enter ratio details"
                                            value={otherDetails.energyDetails}
                                            className="textBox5"
                                            style={{
                                                border: errors.energyDetails ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',
                                                padding: '3px 3px 3px 15px',
                                                marginTop: "0.3rem",
                                                maxLines: '20',
                                                borderRadius: "5px",
                                                width: "90%",
                                                resize: 'none'
                                            }}
                                            onChange={(e) => (setOtherDetails((prev) => { return { ...prev, energyDetails: e.target.value } }))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> {finishButtonText} </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default MlPageEnergy;