
import { Container, Row, Col } from "react-grid-system";
import NavBar from "./NavBar";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import addIcon from '../assets/add.svg'
import helpIcon from '../assets/help.svg'
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import * as React from 'react';
import "./Bfutton.css";

function IotDeviceSection_1() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); 
    };
    const [alertmessage, setAlertmessage] = useState('');
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };


    useEffect(() => {
        sessionStorage.setItem('startedComponentSelected', 'started')
    }, [])
    const [iotNameheading, setIotNameHeading] = useState(() => {
        const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
        if (componentSelected === 'Complete System') {
            const savedName = sessionStorage.getItem('permanentdetails');
            return savedName ? `Iot device ${(JSON.parse(savedName)).iotname} (Part 1)` : "Iot device";
        } else {
            return "Iot device"
        }

    });
    const [sensor, setSensorData] = useState([{ sensor: "", senstandard: "" }]);
    const [actuator, setActuatorData] = useState([{ actuator: "", acstandard: "" }]);
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const [IotDetails, setIotDetails] = useState(
        () => {
            const IotdetailsSaved = sessionStorage.getItem('iotdetails_1');
            return IotdetailsSaved ? JSON.parse(IotdetailsSaved) :
                {
                    sensorArray: sensor,
                    actuatorArray: actuator,
                    device_name: '',
                    overview: '',
                    specific_features: '',
                }
        }
    );

    const [errordetail, setError] = useState({
        sensorArray: false,
        actuatorArray: false,
        device_name: false,
        overview: false,
        specific_features: false,
    });

    const areSomeSensorsEmpty = () => {
        return sensor.some((sensorObj) => {
            return sensorObj.sensor === "" || sensorObj.senstandard === "";
        });
    };

    const areSomeActucatorsEmpty = () => {
        return actuator.some((actucatorObj) => {
            return actucatorObj.actuator === "" || actucatorObj.acstandard === "";
        });
    };

    function handleButton() {
        let newErrors = {};
        newErrors.overview = !IotDetails.overview;
        newErrors.device_name = !IotDetails.device_name;
        newErrors.specific_features = !IotDetails.specific_features;

       if (areSomeSensorsEmpty()) {
            setAlertmessage('Please fill all sensor fields or remove unnecessary fields');
            handleClickOpen2();
            newErrors.sensorArray = true;
        } else {
            newErrors.sensorArray = false;
        }

        if (areSomeActucatorsEmpty()) {
            setAlertmessage('Please fill all actucator fields or remove unnecessary fields');
            handleClickOpen2();
            newErrors.actuatorArray = true;
        } else {
            newErrors.actuatorArray = false;
        }

        setError(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        console.log(JSON.parse(sessionStorage.getItem('iotdetails_1')));
        console.log(JSON.parse(sessionStorage.getItem('permanentdetails')));

        navigate('/iotdevicesection_2');
    }

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    useEffect(() => {
        setSensorData(IotDetails.sensorArray);
        setActuatorData(IotDetails.actuatorArray);
    }, [IotDetails]);


    useEffect(() => {
        sessionStorage.setItem('iotdetails_1', JSON.stringify(IotDetails))
    }, [IotDetails])

    const handleClickSensor = () => {
        setSensorData([...sensor, { sensor: "", senstandard: "" }]);
    };

    const handleChangeSensor = (e, i) => {
        const { name, value } = e.target;
        const onchangeVal = [...sensor];
        onchangeVal[i][name] = value;
        setSensorData(onchangeVal);
        setIotDetails((prev) => ({ ...prev, sensorArray: onchangeVal }));
    };

    const handleDeleteSensor = (i) => {
        const deleteVal = [...sensor];
        deleteVal.splice(i, 1);
        setSensorData(deleteVal);
        setIotDetails((prev) => ({ ...prev, sensorArray: deleteVal }));
    };

    const handleClickActuator = () => {
        setActuatorData([...actuator, { actuator: "", acstandard: "" }]);
    };

    const handleChangeActuator = (e, i) => {
        const { name, value } = e.target;
        const onchangeVal = [...actuator];
        onchangeVal[i][name] = value;
        setActuatorData(onchangeVal);
        setIotDetails((prev) => ({ ...prev, actuatorArray: onchangeVal }));
    };

    const handleDeleteActuator = (i) => {
        const deleteVal = [...actuator];
        deleteVal.splice(i, 1);
        setActuatorData(deleteVal);
        setIotDetails((prev) => ({ ...prev, actuatorArray: deleteVal }));
    };

    useEffect(() => {
        sessionStorage.setItem('devicedetailsselect', 'learn');
    }, []);

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={6} >
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>{iotNameheading} </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem', display: 'flex', marginBottom: '0.5rem' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",
                                            paddingTop: '5px',
                                            paddingRight: '5px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Device Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <input
                                        value={IotDetails.device_name}
                                        type="text"
                                        name="device_name"
                                        placeholder="Enter name"
                                        className="inputBox"
                                        style={{
                                            border: errordetail.device_name ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '0.1rem',
                                            borderRadius: "5px",
                                            width: "60%",
                                        }}
                                        onChange={(e) => (setIotDetails((prev) => { return { ...prev, device_name: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem', marginBottom: '0.3rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        What specific features do you need in Device <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <textarea
                                        value={IotDetails.specific_features}
                                        type="text"
                                        name="specific_features"
                                        placeholder="Enter specific features"
                                        className="textBoxIot"
                                        style={{
                                            border: errordetail.specific_features ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setIotDetails((prev) => { return { ...prev, specific_features: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="App">
                                    <p
                                        className="text"
                                        style={{
                                            fontFamily: 'poppins',
                                            color: 'rgba(71, 85, 105, 1)',
                                        }}>List down the sensors that need to be used
                                        (e.g., temperature, humidity) Specify if we need to consider any industrial standards when selecting the sensors <span style={{ color: "red" }}>*</span>
                                        <span ><a href="https://protonest.co/sensor-for-an-iot-project/" target="_blank" rel="noopener noreferrer">
                                            <img className="plusImage" src={helpIcon} alt="help" style={{
                                                paddingLeft: '3px',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span></p>
                                    <img className="plusImage" onClick={handleClickSensor} src={addIcon} alt="add" />
                                    {
                                        sensor.map((val, i) =>
                                            <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <input
                                                    type="text"
                                                    name="sensor"
                                                    value={val.sensor}
                                                    placeholder="sensor name"
                                                    className="inputBox"
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                        padding: '3px 3px 3px 15px',
                                                        marginRight: '10px',
                                                        marginTop: "0.3rem",
                                                        borderRadius: "5px",

                                                        width: "45%",
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeSensor(e, i);
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    name="senstandard"
                                                    placeholder="standard"
                                                    className="inputBox"
                                                    value={val.senstandard}
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                        padding: '3px 3px 3px 15px',
                                                        marginTop: "0.3rem",
                                                        marginRight: '10px',
                                                        borderRadius: "5px",

                                                        width: "45%",
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeSensor(e, i);
                                                    }}
                                                />
                                                <button
                                                    onClick={() => handleDeleteSensor(i)}
                                                    className="inputBox"
                                                    style={{

                                                        padding: '3 0 3 0',
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                    }}
                                                >Delete</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col sm={12} md={6} style={{ marginTop: '2rem' }}>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '0.1rem', marginBottom: '0.1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Brief Overview of the Device <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <textarea
                                        value={IotDetails.overview}
                                        type="text"
                                        name="overview"
                                        placeholder="Enter overview"
                                        className="textBoxIot2"
                                        style={{
                                            border: errordetail.overview ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setIotDetails((prev) => { return { ...prev, overview: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>

                        <Row style={{ height: '100%' }}>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0', paddingBottom: '10px' }}>
                                <div className="App">
                                    <p className="text" style={{ fontFamily: 'poppins', color: 'rgba(71, 85, 105, 1)', }}>List down the Actuators
                                        that need to be used(e.g., motors, lights) Specify if we need to consider any industrial standards when selecting the actuators <span style={{ color: "red" }}>*</span>
                                        <span ><a href="https://protonest.co/actuator-for-an-iot-project/" target="_blank" rel="noopener noreferrer"><
                                            img src={helpIcon} alt="help"
                                            className="plusImage"
                                            style={{ paddingLeft: '3px', cursor: 'pointer', verticalAlign: 'middle' }} /></a></span></p>
                                    <img className="plusImage" onClick={handleClickActuator} src={addIcon} alt="add" />
                                    {
                                        actuator.map((val, i) =>
                                            <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <input
                                                    type="text"
                                                    name="actuator"
                                                    placeholder="actuator name"
                                                    value={val.actuator}
                                                    className="inputBox"
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                        padding: '3px 3px 3px 15px',
                                                        marginRight: '10px',
                                                        marginTop: "0.3rem",
                                                        borderRadius: "5px",

                                                        width: "45%",
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeActuator(e, i);
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    name="acstandard"
                                                    placeholder="standard"
                                                    className="inputBox"
                                                    value={val.acstandard}
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                        padding: '3px 3px 3px 15px',
                                                        marginTop: "0.3rem",
                                                        marginRight: '10px',
                                                        borderRadius: "5px",

                                                        width: "45%",
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeActuator(e, i);
                                                    }}
                                                />
                                                <button
                                                    onClick={() => handleDeleteActuator(i)}
                                                    className="inputBox"
                                                    style={{

                                                        padding: '3 0 3 0',
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                    }}
                                                >Delete</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleButton} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningAmberIcon style={{ marginRight: '0.5rem', color: 'white' }} />
                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Alert
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        {alertmessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose2}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px',
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default IotDeviceSection_1;