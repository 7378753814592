import { Container, Row, Col } from "react-grid-system";
import NavBar from "./NavBar";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { ref, set, update } from "firebase/database";
import { database } from "../firebase";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';
import "./Bfutton.css";

const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function NonTouchDisplayPage() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    useEffect(() => {
        sessionStorage.setItem('uiselected', 'ntd');
    }, []);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };


    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2) // Convert object to a readable string format
        };

        emailjs.send(
            SERVICE_ID,      // Replace with your EmailJS service ID
            TEMPLATE_ID,     // Replace with your EmailJS template ID
            emailParams,            // Parameters to send (like finalIotObject)
            PUBLIC_KEY          // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const [changesAdded, setChangesAdded] = useState(false);
    const [selectedDisplay, setSelectedDisplay] = useState(
        () => {
            const savedDetails = sessionStorage.getItem('nontouchdisplaydetails');
            return savedDetails ? JSON.parse(savedDetails) :
                {
                    display: 'TFT Screens',
                    size: '1 inch'
                }
        }
    );
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
            // if (!changesAdded) {
            //     // Optionally add a warning for unsaved changes
            //     event.preventDefault(); // For most browsers
            //     event.returnValue = '';  // For Chrome
            // }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const [isOtherClicked, setIsOtherClicked] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('nontouchdisplaydetails', JSON.stringify(selectedDisplay));
    }, [selectedDisplay])

    const [error, setError] = useState({
        size: false,
    })

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setSelectedDisplay((prev) => ({ ...prev, [name]: value }));

        if (name === 'size' && value === 'Other') {
            setIsOtherClicked(true);
        } else if (value === '1 inch' || value === "2.42 inch" || value === "3.5 inch" || value === "4.3 inch" || value === "5 inch") {
            setIsOtherClicked(false);
        }
    }

    function handleClickNext() {
        let newError = {};
        if (selectedDisplay.size === 'Other' || selectedDisplay.size === '') {
            newError.size = true;
        } else {
            newError.size = false;
        }

        setError(newError);

        if (Object.values(newError).some((error) => error)) {
            return;
        }

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));


        if (componentSelected === 'Complete System') {
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
            let iot7 = (sessionStorage.getItem('batterydetails'));
            let iot8 = (sessionStorage.getItem('enclosuredesigndetails'));
            let iot9 = (sessionStorage.getItem('pcbdetailssaved'));

            let finalIotObject = {
                iot_page_1: iot1,
                iot_page_2: iot2,
                iot_page_3: iot3,
                iot_page_4: iot4,
                iot_page_5: iot5,
                ML_Energyconservation_Battery: iot6,
                non_touch_Display: selectedDisplay
            };

            if (iot7) {
                iot7 = JSON.parse(iot7)
                finalIotObject = {
                    ...finalIotObject,
                    battery_details: iot7
                };
            }

            if (iot8) {
                iot8 = JSON.parse(iot8)
                finalIotObject = {
                    ...finalIotObject,
                    enclosure_details: iot8
                };
            }

            if (iot9) {
                iot9 = JSON.parse(iot9)
                finalIotObject = {
                    ...finalIotObject,
                    pcb_details: iot9
                };
            }

            let permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            const iotDeviceKeyName = `iot_device_${permanentdetails.numberOfIotDevices}`
            let detailsSaved = {};

            let dateNow = 'date';
            if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                dateNow = Date.now();
                sessionStorage.setItem('permanentdetails', JSON.stringify(
                    {
                        ...permanentdetails,
                        dateNow: dateNow
                    }
                ));
                permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                detailsSaved = {
                    personalDetails: personDetailsSaved,
                    component: componentSelected,
                    [iotDeviceKeyName]: finalIotObject
                }

                const dbRef = ref(database, `${dateNow}`);
                set(dbRef, detailsSaved).then(() => {
                    console.log("Saved to database");
                    if (numberOfIotDevicesTotal === '1' || permanentdetails.numberOfIotDevices === '1') {
                        sendEmail(detailsSaved);
                        sessionStorage.clear();
                        setChangesAdded(true);
                        handleClickOpen();

                        // setTimeout(() => {
                        //     sessionStorage.clear();
                        //     window.location.href = 'https://protonest.co/';
                        // }, 100);
                    } else {
                        permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                        sessionStorage.setItem('permanentdetails',
                            JSON.stringify(
                                {
                                    ...permanentdetails,
                                    numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                    iotname: (permanentdetails.iotname) + 1
                                }
                            )
                        );
                        sessionStorage.removeItem('iotdetails_1');
                        sessionStorage.removeItem('savedprotocoldetails');
                        sessionStorage.removeItem('storagedetails');
                        sessionStorage.removeItem('iotchoices_1');
                        sessionStorage.removeItem('iotchoices_2');
                        sessionStorage.removeItem('iototherdetails');
                        sessionStorage.removeItem('batterydetails');
                        sessionStorage.removeItem('enclosuredesigndetails');
                        sessionStorage.removeItem('pcbdetailssaved');
                        sessionStorage.removeItem('nontouchdisplaydetails');
                        sessionStorage.removeItem('uiselected');
                        navigate('/iotdevicesection_1');
                    }

                }).catch((error) => {
                    console.error("Error saving in database: ", error);
                })
            } else {
                dateNow = permanentdetails.dateNow;
                detailsSaved = {
                    [iotDeviceKeyName]: finalIotObject
                }
                const dbRef = ref(database, `${dateNow}`);
                update(dbRef, detailsSaved).then(() => {
                    console.log("Saved to database");
                    if (numberOfIotDevicesTotal === 1 || permanentdetails.numberOfIotDevices === 1) {
                        sendEmail(detailsSaved);
                        sessionStorage.clear();
                        setChangesAdded(true);
                        handleClickOpen();

                        // setTimeout(() => {
                        //     sessionStorage.clear();
                        //     window.location.href = 'https://protonest.co/';
                        // }, 100);
                    } else {
                        sessionStorage.setItem('permanentdetails',
                            JSON.stringify(
                                {
                                    ...permanentdetails,
                                    numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                    iotname: (permanentdetails.iotname) + 1
                                }
                            )
                        );
                        sessionStorage.removeItem('iotdetails_1');
                        sessionStorage.removeItem('savedprotocoldetails');
                        sessionStorage.removeItem('storagedetails');
                        sessionStorage.removeItem('iotchoices_1');
                        sessionStorage.removeItem('iotchoices_2');
                        sessionStorage.removeItem('iototherdetails');
                        sessionStorage.removeItem('batterydetails');
                        sessionStorage.removeItem('enclosuredesigndetails');
                        sessionStorage.removeItem('pcbdetailssaved');
                        sessionStorage.removeItem('nontouchdisplaydetails');
                        sessionStorage.removeItem('uiselected');
                        navigate('/iotdevicesection_1');
                    }

                }).catch((error) => {
                    console.error("Error saving in database: ", error);
                })
            }


        } else {
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
            let iot7 = (sessionStorage.getItem('batterydetails'));
            let iot8 = (sessionStorage.getItem('enclosuredesigndetails'));
            let iot9 = (sessionStorage.getItem('pcbdetailssaved'));
            let finalIotObject = {
                iot_page_1: iot1,
                iot_page_2: iot2,
                iot_page_3: iot3,
                iot_page_4: iot4,
                iot_page_5: iot5,
                ML_Energyconservation_Battery: iot6,
                non_touch_Display: selectedDisplay
            };

            if (iot7) {
                iot7 = JSON.parse(iot7)
                finalIotObject = {
                    ...finalIotObject,
                    battery_details: iot7
                };
            }

            if (iot8) {
                iot8 = JSON.parse(iot8)
                finalIotObject = {
                    ...finalIotObject,
                    enclosure_details: iot8
                };
            }

            if (iot9) {
                iot9 = JSON.parse(iot9)
                finalIotObject = {
                    ...finalIotObject,
                    pcb_details: iot9
                };
            }

            const detailsSaved = {
                personalDetails: personDetailsSaved,
                component: componentSelected,
                details: finalIotObject
            }
            const dbRef = ref(database, `${Date.now()}`);
            set(dbRef, detailsSaved).then(() => {
                console.log("Saved to database");
            }).catch((error) => {
                console.error("Error saving in database: ", error);
            })
            sendEmail(detailsSaved);
            sessionStorage.clear();
            setChangesAdded(true);
            handleClickOpen();

            // setTimeout(() => {
            //     sessionStorage.clear();
            //     window.location.href = 'https://protonest.co/';
            // }, 100);

        }

        console.log(selectedDisplay);
    }

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={6} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '20px',

                        paddingLeft: "3rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Row style={{ width: '100%', }}>
                        <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>Non-Touch Display</p>
                    </Row>
                    <Col sm={12} md={6} >
                        <Row >
                            <div>
                                <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                    What is the type of touch display you prefer? (Display technology)
                                </label>
                                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                    {["TFT", "OLED", "AMOLED", "LCD", "IPS LCD", "E-Ink", "Other"].map((tech) => (
                                        <label className="text" key={tech} style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="radio"
                                                value={`${tech} Screens`}
                                                name="display"
                                                checked={tech === "Other" ? (selectedDisplay.display !== "TFT Screens" && selectedDisplay.display !== "OLED Screens" && selectedDisplay.display !== "AMOLED Screens" && selectedDisplay.display !== "LCD Screens" && selectedDisplay.display !== "IPS LCD Screens" && selectedDisplay.display !== "E-Ink Screens") : selectedDisplay.display === `${tech} Screens`}
                                                style={{ cursor: 'pointer' }}
                                                onChange={handleRadioChange}
                                            />
                                            {`${tech} Screens`}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </Row>
                        {selectedDisplay.display !== "TFT Screens" && selectedDisplay.display !== "OLED Screens" && selectedDisplay.display !== "AMOLED Screens" && selectedDisplay.display !== "LCD Screens" && selectedDisplay.display !== "IPS LCD Screens" && selectedDisplay.display !== "E-Ink Screens" &&
                            <Row>
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginTop: '0.5rem', paddingBottom: '10px' }}>
                                        <input
                                            type="text"
                                            name="display"
                                            placeholder="Enter display type"
                                            value={selectedDisplay.display==="Other Screens"?"":selectedDisplay.display}
                                            className="inputBox"
                                            style={{
                                                border: error.type ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',

                                                padding: '3px 3px 3px 15px',
                                                marginLeft: '0.3rem',
                                                borderRadius: "5px",

                                                width: "70%",
                                            }}
                                            onChange={(e) => (setSelectedDisplay((prev) => { return { ...prev, display: e.target.value } }))}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        <Row style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <div >
                                <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                    What should be the size of the display?
                                </label>
                                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                    {["1 inch", "2.42 inch", "3.5 inch", "4.3 inch", "5 inch", "Other"].map((size) => (
                                        <label className="text" key={size} style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="radio"
                                                value={size}
                                                name="size"
                                                checked={size === 'Other' ? ((selectedDisplay.size !== '1 inch' && selectedDisplay.size !== '2.42 inch' && selectedDisplay.size !== '3.5 inch' && selectedDisplay.size !== '4.3 inch' && selectedDisplay.size !== '5 inch') || isOtherClicked) : selectedDisplay.size === size}
                                                style={{ cursor: 'pointer' }}
                                                onChange={handleRadioChange}
                                            />
                                            {size}
                                        </label>
                                    ))}
                                    {(selectedDisplay.size !== '1 inch' && selectedDisplay.size !== '2.42 inch' && selectedDisplay.size !== '3.5 inch' && selectedDisplay.size !== '4.3 inch' && selectedDisplay.size !== '5 inch') && (
                                        <input
                                            type="text"
                                            name="Other"
                                            className="inputBox"
                                            value={selectedDisplay.size==="Other"?"":selectedDisplay.size}
                                            placeholder="Enter size"
                                            style={{
                                                border: error.size ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',
                                                padding: '3px 3px 3px 15px',
                                                marginTop: "0.3rem",
                                                marginRight: '10px',
                                                borderRadius: "5px",

                                                width: "45%",
                                            }}
                                            onChange={(e) => {
                                                setSelectedDisplay((prevValue) => ({ ...prevValue, size: e.target.value }));
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div>
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Finish </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default NonTouchDisplayPage;