import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXTwitter, faMedium } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col } from "react-grid-system";
import img from '../assets/logo.png'
import { Button } from "@mui/material";
import '../components/Footer.css'

const items = [
    { name: "X", icon: faXTwitter, link: "https://x.com/protonestsl/" },
    { name: "LinkedIn", icon: faLinkedin, link: "https://www.linkedin.com/company/protonest/" },
    { name: "Medium", icon: faMedium, link: "https://medium.com/@protonestsl" },
];

const handleNavigation = () => {
    window.open('https://protonest.co/contact-us/', '_blank', 'noopener,noreferrer');
};


const Footer = () => {
    return (
        <>
            <div className="footer">
                <Row className="rowClass">
                    <Col sm={12} md={4}>
                        <div className="divClass">
                            <img style={{ height: '6rem' }} src={img} alt="logo" />
                        </div>
                    </Col>
                    <Col sm={12} md={4}>
                        <div className="divClass">
                            <p
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: '700',
                                    marginBottom: "0.5rem",
                                    fontFamily: "poppins",
                                    color: "white",
                                }}
                            >
                                Ready To Get Started</p>
                        </div>
                    </Col>
                    <Col sm={12} md={4}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <button className="contact-button" onClick={handleNavigation}>
                                Contact Us
                            </button>
                        </div>
                    </Col>
                </Row>
                <hr style={{ border: '0.5px solid #54595F', width: '80%' }} />
                <Row className="rowClass">
                    <Col sm={12} md={12 / 3}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', height: '100%', width: '100%' }}>
                            <label
                                className="text"
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: '700',
                                    fontFamily: "poppins",
                                    color: "white",
                                }}
                            >
                                Subscribe to our news letter
                            </label>
                            <br />
                            <div style={{ display: 'flex', width: '100%' }}>
                                <input
                                    className="inputBox"
                                    type="email"
                                    name="person_Email"
                                    placeholder="someone@example.com"
                                    style={{
                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                        fontFamily: 'poppins',
                                        padding: '3px 3px 3px 15px',
                                        borderRadius: "5px",
                                        height: '2rem',
                                        width: "100%",
                                    }}
                                    onChange={(e) => {

                                    }} />

                                <div>
                                    <Button className="send-button">Send</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={12 / 6}>
                        <div className="featuresContainer">
                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left', marginTop: '0', paddingTop: '0' }}>
                               <li style={{ fontFamily: 'poppins', color: 'white', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginTop: '0', paddingTop: '0' }}>
                                    Services
                                </li>
                                <a href="https://protonest.co/our-services/" style={{ textDecoration: 'none', color: 'inherit' }}><li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 0.5rem' }}>
                                    Concept development and Ideation
                                </li></a>
                                <a href="https://protonest.co/our-services/" style={{ textDecoration: 'none', color: 'inherit' }}> <li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 0.5rem' }}>
                                    Hardware & Software Solutions
                                </li></a>
                                <a href="https://protonest.co/our-services/" style={{ textDecoration: 'none', color: 'inherit' }}> <li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 0.5rem ' }}>
                                    Testing & Validation
                                </li></a>
                                <a href="https://protonest.co/our-services/" style={{ textDecoration: 'none', color: 'inherit' }}><li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 0.5rem' }}>
                                    Consultation
                                </li></a>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={12} md={12 / 6}>
                        <div className="featuresContainer">
                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left', marginTop: '0', paddingTop: '0' }}>
                                <li style={{ fontFamily: 'poppins', color: 'white', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginTop: '0', paddingTop: '0' }}>
                                    About
                                </li>
                                <a href="https://protonest.co/about-us/" style={{ textDecoration: 'none', color: 'inherit' }}><li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 0.5rem' }}>
                                    Our Story
                                </li></a>
                                <li style={{ fontFamily: 'poppins', color: 'black', margin: '0.2rem 0 0.5rem' }}>
                                    Team
                                </li>
                                <li style={{ fontFamily: 'poppins', color: 'black', margin: '0.2rem 0 0.5rem ' }}>
                                    Careers
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={12} md={12 / 6}>
                        <div className="featuresContainer">
                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left', marginTop: '0', paddingTop: '0' }}>
                                <li style={{ fontFamily: 'poppins', color: 'white', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginTop: '0', paddingTop: '0' }}>
                                    Navigation
                                </li>
                                <a href="https://protonest.co/our-projects/" style={{ textDecoration: 'none', color: 'inherit' }}><li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 1rem' }}>
                                    Projects
                                </li></a>
                                <a href="https://protonest.co/blog/" style={{ textDecoration: 'none', color: 'inherit' }}> <li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 1rem' }}>
                                    Articles
                                </li> </a>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={12} md={12 / 6}>
                        <div className="featuresContainer">
                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left', marginTop: '0', paddingTop: '0' }}>
                                <li style={{ fontFamily: 'poppins', color: 'white', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginTop: '0', paddingTop: '0' }}>
                                    Help
                                </li>
                                <a href="https://protonest.co/faq/" style={{ textDecoration: 'none', color: 'inherit' }}><li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 1rem' }}>
                                    FAQs
                                </li></a>
                                <a href="https://protonest.co/contact-us/" style={{ textDecoration: 'none', color: 'inherit' }}><li style={{ fontFamily: 'poppins', color: 'white', margin: '0.2rem 0 1rem' }}>
                                    Contact Us
                                </li></a>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <hr style={{ border: '0.5px solid #54595F', width: '80%' }} />
                <Row className="rowClass">
                    <Col sm={12} md={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {items.map((item, index) => (
                                <>
                                    <div className="icon-container">
                                        <a
                                            key={index}
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'inherit', fontSize: '1.5rem' }} 
                                        >
                                            <FontAwesomeIcon icon={item.icon} title={item.name} />
                                        </a>

                                    </div>
                                </>

                            ))}
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="lastRow">
                            <p style={{ fontFamily: 'poppins', color: 'white', fontWeight: 'bold', fontSize: '1rem', }}>
                                2024 Protonest <span style={{ fontFamily: 'poppins', color: '#9C88FF', fontWeight: 'bold', fontSize: '1rem', }}>| All Rights Reserved</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Footer;