import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD4iWMWbtDjb7lJpf7X7P5CVS10XV5-RZ4",
  authDomain: "web-questionaire.firebaseapp.com",
  databaseURL: "https://web-questionaire-default-rtdb.firebaseio.com",
  projectId: "web-questionaire",
  storageBucket: "web-questionaire.appspot.com",
  messagingSenderId: "51690443646",
  appId: "1:51690443646:web:5e4d348e5f0bc3ec61192f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const database = getDatabase(app);

export {storage,database};
