import { Button } from "@mui/material";
import { Container, Row, Col } from "react-grid-system";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";
import addIcon from '../assets/add.svg'
import { useNavigate } from "react-router-dom";
import helpIcon from '../assets/help.svg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import * as React from 'react';
import "./Bfutton.css";

function IotDeviceSection_2() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const [alertmessage, setAlertmessage] = useState('');
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };
    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const [iotNameheading, setIotNameHeading] = useState(() => {
        const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
        if (componentSelected === 'Complete System') {
            const savedName = sessionStorage.getItem('permanentdetails');
            return savedName ? `Iot device ${(JSON.parse(savedName)).iotname} (Part 2)` : "Iot device";
        } else {
            return "Iot device"
        }

    });



    const handleCancelClick = () => {
        handleClickOpen3();
    };

    const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
    const [isReShowOptions, setIsReShowOptions] = useState(true);
    const [protocols, setProtocols] = useState([{ protocol: "", protocolFeatures: "" }]);
    const [communicationProtocolDetails, setCommunicationProtocolDetails] = useState(() => {
        const savedProtocolDetails = sessionStorage.getItem('savedprotocoldetails');
        return savedProtocolDetails ? JSON.parse(savedProtocolDetails) : {
            communication_protocol_array: protocols
        };
    });

    const communicationProtocolArray = ['WIFI', 'Cellular', 'UART', 'I2c', 'MQTT', 'Lora', 'UWB', 'BLE', 'Modbus RTU', 'Zigbee', 'ESPNow', 'ESP SmartConfig'];
    const navigate = useNavigate();

    const removeEmptyProtocols = () => {
        if (Array.isArray(protocols) && protocols.length > 0 && protocols[0].protocol === "" && protocols[0].protocolFeatures === "") {
            setProtocols((prevData) => prevData.filter((_, i) => i !== 0));
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        sessionStorage.setItem('savedprotocoldetails', JSON.stringify(communicationProtocolDetails));
    }, [communicationProtocolDetails]);

    useEffect(() => {
        // Set the sensor and actuator arrays from IotDetails state
        setProtocols(communicationProtocolDetails.communication_protocol_array);
    }, [communicationProtocolDetails]);

    const handleClickCommunicator = () => {
        setProtocols([...protocols, { protocol: "", protocolFeatures: "" }]);
    };

    const handleChangeCommunicator = (e, i) => {
        const { name, value } = e.target;
        const onchangeVal = [...protocols];
        onchangeVal[i][name] = value;
        setProtocols(onchangeVal);
        setCommunicationProtocolDetails((prev) => ({ ...prev, communication_protocol_array: onchangeVal }));
    };

    useEffect(() => {
        if (componentSelected === 'Complete System') {
            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if ((permanentdetails.numberOfIotDevices < numberOfIotDevicesTotal) && permanentdetails.sameBackendStorage === 'No') {
                setIsReShowOptions(true);
            } else if ((permanentdetails.numberOfIotDevices < numberOfIotDevicesTotal) && permanentdetails.sameBackendStorage === 'Yes') {
                setIsReShowOptions(false);
            }
        }

    }, [componentSelected])

    const handleDeletCommunicator = (i) => {
        const deleteVal = [...protocols];
        deleteVal.splice(i, 1);
        setProtocols(deleteVal);
        setCommunicationProtocolDetails((prev) => ({ ...prev, communication_protocol_array: deleteVal }));
    };


    const areSomeFieldsEmpty = () => {
        return protocols.some((comObj) => {
            return comObj.protocol === "" || comObj.protocolFeatures === "";
        });
    };


    function handleNextButton() {
        if ((protocols).length < 1) {

            setAlertmessage('Please add atleast one protocol');
            handleClickOpen2();
            return;
        } else if (areSomeFieldsEmpty()) {
            setAlertmessage('Please fill all protocol fields or remove unnecessary fields');
            handleClickOpen2();
            return;
        }
        console.log(JSON.parse(sessionStorage.getItem('savedprotocoldetails')));

        if (isReShowOptions) {
            navigate('/iotdevicesection_3');
        } else {
            navigate('/iotdevicesection_4');
        }

    };

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >

                    <Col sm={12} md={6}>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>{iotNameheading}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",
                                            paddingTop: '5px',
                                            paddingRight: '5px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Select the Communication Protocols that need to be used <span style={{ color: "red" }}>*</span>
                                        <span ><a href="https://protonest.co/communication-method-for-an-iot-project/" target="_blank" rel="noopener noreferrer"><img src={helpIcon} alt="help"
                                            className="plusImage"
                                            style={{
                                                paddingLeft: '3px',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {

                                                setProtocols([...protocols, { protocol: communicationProtocolArray[0], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[0]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[0]} </Button>
                                </div>
                            </Col>

                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[1], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[1]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[1]} </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[2], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[2]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[2]} </Button>
                                </div>
                            </Col>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[3], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[3]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[3]} </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[4], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[4]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[4]} </Button>
                                </div>
                            </Col>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[5], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[5]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[5]} </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[6], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[6]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[6]} </Button>
                                </div>
                            </Col>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[7], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[7]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[7]} </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[8], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[8]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[8]} </Button>
                                </div>
                            </Col>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[9], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[9]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[9]} </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[10], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[10]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[10]} </Button>
                                </div>
                            </Col>
                            <Col sm={12} md={6} style={{ height: '100%', margin: '0' }}>
                                <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                                    <Button
                                        onClick={
                                            () => {
                                                setProtocols([...protocols, { protocol: communicationProtocolArray[11], protocolFeatures: "" }]);
                                                removeEmptyProtocols();
                                            }
                                        }
                                        className="text2"
                                        style={{ backgroundColor: protocols.some(obj => obj.protocol === communicationProtocolArray[11]) ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minWidth: '90%', fontFamily: 'poppins', fontWeight: 'bold' }}>
                                        {communicationProtocolArray[11]} </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} style={{ marginTop: '2.5rem' }}>
                        <Row style={{ height: '100%' }}>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0', paddingBottom: '10px' }}>
                                <div className="App">
                                    <p className="text" style={{ fontFamily: 'poppins', color: 'rgba(71, 85, 105, 1)' }}>
                                        Specify the features to which the selected Protocols Corresponds
                                    </p>
                                    <img className="plusImage" onClick={handleClickCommunicator} src={addIcon} alt="add" />
                                    {
                                        protocols.map((val, i) =>
                                            <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <input
                                                    type="text"
                                                    name="protocol"
                                                    placeholder="protocol"
                                                    value={val.protocol}
                                                    className="inputBox"
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',
                                                        padding: '3px 3px 3px 15px',
                                                        marginRight: '10px',
                                                        marginTop: "0.3rem",
                                                        borderRadius: "5px",
                                                        width: "45%",
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeCommunicator(e, i);
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    name="protocolFeatures"
                                                    placeholder="feature"
                                                    value={val.protocolFeatures}
                                                    className="inputBox"
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',
                                                        padding: '3px 3px 3px 15px',
                                                        marginTop: "0.3rem",
                                                        marginRight: '10px',
                                                        borderRadius: "5px",
                                                        width: "45%",
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeCommunicator(e, i);
                                                    }}
                                                />
                                                <button
                                                    onClick={() => handleDeletCommunicator(i)}
                                                    className="inputBox"
                                                    style={{
                                                        padding: '3 0 3 0',
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',

                                                    }}
                                                >Delete</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleNextButton} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningAmberIcon style={{ marginRight: '0.5rem', color: 'white' }} />
                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Alert
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        {alertmessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose2}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default IotDeviceSection_2;