import { Button } from "@mui/material-next";//
import { Container, Row, Col } from "react-grid-system";//
import NavBar from "./NavBar";//
import "../css/FirstPage.css";
import "../css/ComponentSelectPage.css";
import uploadSvg from "../assets/upload.svg";//
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useRef, useState } from "react";
import fileImg from '../assets/file.svg'//
import closeImg from '../assets/close.svg'//
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ref as refDb, set } from "firebase/database";
import { database, storage } from "../firebase";
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import "./Bfutton.css";
import { stringify } from "postcss";

const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function UploadFilesDirectlyPage() {

    const [projectDetails, setProjectDetails] = useState(() => {
        const savedDetails = sessionStorage.getItem('projectDetails');
        return savedDetails ? JSON.parse(savedDetails) : {
            project_title: '',
        };
    });

    useEffect(()=>{
        sessionStorage.setItem('projectDetails',JSON.stringify(projectDetails))
    },[projectDetails]);

    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2) // Convert object to a readable string format
        };

        emailjs.send(
            SERVICE_ID,      // Replace with your EmailJS service ID
            TEMPLATE_ID,     // Replace with your EmailJS template ID
            emailParams,            // Parameters to send (like finalIotObject)
            PUBLIC_KEY          // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const [changesAdded, setChangesAdded] = useState(false);
    const fileTypes = ['ZIP', 'RAR', 'PNG', 'JPG', 'JPEG', 'GIF', 'PDF', 'DOC', 'DOCX'];
    const [fileSelected, setFileSelected] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
    const [isUploading, setIsUploading] = useState(false);
    const uploadTaskRef = useRef(null);
    const navigate = useNavigate();



    const upload = () => {
        if (fileSelected) {
            const dateStoring = Date.now().toString();
            const storageRef = ref(storage, `${fileSelected.name} ${dateStoring}`);
            const uploadTask = uploadBytesResumable(storageRef, fileSelected, { contentType: "application/zip" });
            uploadTaskRef.current = uploadTask;

            // Monitor the upload progress
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    setIsUploading(true); // Set uploading status to true
                },
                (error) => {
                    if (error.code === 'storage/canceled') {
                        console.log("Upload canceled by the user.");
                    } else {
                        console.error("Error uploading file: ", error);
                    }
                },
                () => {
                    // Upload completed successfully, now get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
                        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));
                        const projectDetailsSaved = JSON.parse(sessionStorage.getItem('projectDetails')).project_title;

                        const detailsSaved = {
                            personal_Details: personDetailsSaved,
                            component: componentSelected,
                            information_zip: downloadURL,
                            project_title:projectDetailsSaved
                        }
                        const dbRef = refDb(database, `${Date.now()}`);
                        set(dbRef, detailsSaved).then(() => {
                            console.log("Saved to database");
                        }).catch((error) => {
                            console.error("Error saving in database: ", error);
                        })
                        sendEmail(detailsSaved);
                        sessionStorage.clear();
                        setChangesAdded(true);
                        handleClickOpen();
                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    });


                }
            );
        }
    };



    const handleChange = (fileNew) => {
        if (fileSelected === null) {
            setFileSelected(fileNew);
            console.log(`name of file : ${fileNew.name}`)
        } else {
            alert('one file is already selected. Please remove that file to add new file.');
        }

    };

    const removeFile = () => {
        if (uploadTaskRef.current) {
            uploadTaskRef.current.cancel();
        }
        setFileSelected(null);
        setUploadProgress(0); 
        setIsUploading(false); 
    };

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const [error, setError] = useState({
        project_title: false,
    })

    const handleNextButton = () => {
        let newError = {};
        if (! projectDetails.project_title) {
            newError.project_title = true;
        }else{
            newError.project_title = false;
        }

        setError(newError);

        if (Object.values(newError).some((error) => error)) {
            return;
        }

        if (fileSelected !== null) {

            if (isUploading === false) {
                upload();
            }
        } else {
            handleClickOpen2();
        }

    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.clear();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('devicedetailsselect', 'upload');
        sessionStorage.removeItem('uiselected');
    }, []);

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={12}>
                    <Row >
                            <Col sm={12} md={12} style={{ margin: '0' }}>
                                <div style={{ marginTop: '2rem' }}>
                                    <label className="text" style={{ fontWeight: '500', marginBottom: "0.5rem", fontFamily: "poppins", color: "rgba(71, 85, 105, 1)" }}>
                                        Project Title<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="title"
                                        className="inputBox"
                                        style={{
                                            border: error.project_title ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 15px',
                                            marginTop: "0.3rem",
                                            borderRadius: "5px",
                                            width: "90%",
                                        }}
                                        value={projectDetails.project_title}
                                        onChange={(e) => {
                                            setProjectDetails((prevState) => ({ ...prevState, project_title: e.target.value }));
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div style={{ width: '100%', paddingTop: '5px' }}>
                            <p
                                className="text"
                                style={{
                                    fontFamily: 'poppins', fontWeight: '500',
                                    color: 'rgba(71, 85, 105, 1)'
                                }}>
                                Add a document containing all the requirements<span style={{ color: 'red' }}> *</span>
                            </p>
                        </div>
                        < div
                            style={{
                                border: '1px dashed rgba(0, 0, 0, 1)',
                                width: '100%',
                                height: '25%',
                                borderRadius: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            <Col sm={12} md={12} style={{ height: '100%' }}>
                                <FileUploader

                                    handleChange={handleChange}
                                    name="file"
                                    types={fileTypes}
                                >
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }} >
                                        <p
                                            className="text"
                                            style={{ fontFamily: 'poppins', fontWeight: '500', color: 'rgba(71, 85, 105, 1)' }}>
                                            Drop file here (jpg/png/pdf/docx/zip)
                                        </p>
                                        <img src={uploadSvg} alt="upload" />
                                    </div>

                                </FileUploader>
                            </Col>

                        </div>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ width: '100%', paddingTop: '10px', display: 'flex' }}>

                                    {(fileSelected !== null &&
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingRight: '15px' }}>
                                            <img
                                                className="imageWidth"
                                                src={closeImg}
                                                alt="remove file"
                                                style={{ cursor: 'pointer', paddingBottom: '5px' }}
                                                onClick={removeFile}
                                            />
                                            <img className="imageWidth" src={fileImg} alt="file icon" style={{ margin: "0 0 0 0", paddingBottom: '5px' }} />
                                            <p
                                                className="text"
                                                style={{ fontFamily: 'poppins', fontWeight: '500', color: 'rgba(71, 85, 105, 1)', margin: "0 0 0 0" }}>{fileSelected.name}</p>
                                        </div>
                                    )}

                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                {isUploading && (
                                    <div style={{ marginBottom: '1rem' }}>
                                        <p
                                            className="text"
                                            style={{ fontFamily: 'poppins', color: 'rgba(71, 85, 105, 1)' }}>
                                            Uploading: {uploadProgress.toFixed(2)}%
                                        </p>
                                        <div style={{
                                            backgroundColor: 'rgba(71, 85, 105, 0.3)',
                                            height: '10px',
                                            borderRadius: '5px',
                                            width: '100%',
                                        }}>
                                            <div style={{
                                                backgroundColor: '#3b82f6',
                                                height: '100%',
                                                borderRadius: '5px',
                                                width: `${uploadProgress}%`,
                                                transition: 'width 0.2s ease'
                                            }}></div>
                                        </div>
                                    </div>
                                )}

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div>
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button
                            onClick={handleBackClick}
                            className="text"
                            style={{
                                backgroundColor: 'rgba(71, 85, 105, 1)',
                                borderRadius: '6px',
                                color: 'white',
                                minHeight: '40px',
                                minWidth: '100px',
                                marginRight: '20px',
                                fontFamily: 'poppins',
                                fontWeight: 'bold',
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={handleNextButton}
                            className="text"
                            style={{
                                backgroundColor: 'rgba(156, 136, 255, 1)',
                                borderRadius: '6px',
                                color: 'white',
                                minHeight: '40px',
                                minWidth: '100px',
                                fontFamily: 'poppins',
                                fontWeight: 'bold',
                            }}
                        >
                            Finish
                        </Button>
                    </Col>
                </Row>
            </div>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningAmberIcon style={{ marginRight: '0.5rem', color: 'white' }} />
                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Alert
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Please upload file to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose2}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default UploadFilesDirectlyPage;