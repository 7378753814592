import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Topic.css";
import classes from "./Layout.module.scss";

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <div style={{ marginTop: '50px', marginBottom: '0' }}>
                <p className="topic1" style={{ margin: '0 0 10px 0' }}>
                    Let's Bring Your IoT Idea to Life
                </p>
                <p className="topic2" style={{ margin: '0' }} >
                    Tell Us About Your Project - Let's make it happen!
                </p>
            </div>
            <div >{children}</div>
            <Footer />
        </>
    );
};

export default Layout;