import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import NavBar from "./NavBar";
import '../css/FirstPage.css'
import { useNavigate } from "react-router-dom";
import "./Bfutton.css";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';

function IotDeviceSection_4() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const [iotNameheading, setIotNameHeading] = useState(() => {
        const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
        if (componentSelected === 'Complete System') {
            const savedName = sessionStorage.getItem('permanentdetails');
            return savedName ? `Iot device ${(JSON.parse(savedName)).iotname} (Part 4)` : "Iot device";
        } else {
            return "Iot device"
        }

    });

    const handleCancelClick = () => {
        handleClickOpen3();
    };

    const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
    const [isShowNextPage, setIsShowNextPage] = useState(true);
    const [IotDetails, setIotDetails] = useState(() => {
        const savedIotDetails = sessionStorage.getItem('iotchoices_1');
        return savedIotDetails ? JSON.parse(savedIotDetails) : {
            fuse: 'No',
            switch: 'No',
            buttons: 'No',
            enclosure: 'No',
            pcb: 'No',
            cost: '',
            btnDetails: ''
        }
    });

    useEffect(() => {

        if (sessionStorage.getItem('pcbdetailssaved')) {
            if (IotDetails.pcb === 'No') {

                sessionStorage.removeItem('pcbdetailssaved');
            }
        }

        if (sessionStorage.getItem('enclosuredesigndetails')) {
            if (IotDetails.enclosure === 'No') {
                sessionStorage.removeItem('enclosuredesigndetails');
            }
        }


    }, [IotDetails]);

    useEffect(() => {
        if (componentSelected === 'Complete System') {
            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if ((permanentdetails.numberOfIotDevices < numberOfIotDevicesTotal) && (permanentdetails.singleapp === 'No' || permanentdetails.integrateForAll === 'No')) {
                setIsShowNextPage(true);
            } else if ((permanentdetails.numberOfIotDevices < numberOfIotDevicesTotal) && (permanentdetails.singleapp === 'Yes' && permanentdetails.integrateForAll === 'Yes')) {
                setIsShowNextPage(false);
            }
        }
    }, [componentSelected]);

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const [error, setError] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('iotchoices_1', JSON.stringify(IotDetails));
    }, [IotDetails]);

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setIotDetails((prev) => ({ ...prev, [name]: value }));
    }

    function handleNextButton() {
        console.log(JSON.parse(sessionStorage.getItem('iotchoices_1')));

        if (isShowNextPage) {
            navigate('/iotdevicesection_5');
        } else {
            navigate('/MlPageEnergy');
        }

    };

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingBottom: '1rem',
                        backgroundColor: "#ffffff",
                    }}
                >

                    <Col sm={12} md={6} style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>{iotNameheading}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="margin" >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need an external fuse?(If AC) <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {['Yes', 'No'].map((fuse) => (
                                            <label className="text" key={fuse} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={fuse}
                                                    name="fuse"
                                                    checked={IotDetails.fuse === fuse}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {fuse}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="margin" >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '400',
                                            marginBottom: "0.5rem",
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        What should be the unit component cost?
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="cost"
                                        value={IotDetails.cost}
                                        placeholder="Enter cost"
                                        className="inputBox"
                                        style={{
                                            border: error ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            borderRadius: "5px",

                                            width: "50%",
                                        }}
                                        onChange={(e) => {
                                            setIotDetails((prevState) => ({ ...prevState, cost: e.target.value, }));
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="margin"  >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need Power switch? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {['Yes', 'No'].map((switchMap) => (
                                            <label className="text" key={switchMap} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={switchMap}
                                                    name="switch"
                                                    checked={IotDetails.switch === switchMap}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {switchMap}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '1rem' }}>
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need any external buttons? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {['Yes', 'No'].map((need) => (
                                            <label className="text" key={need} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={need}
                                                    name="buttons"
                                                    checked={IotDetails.buttons === need}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {need}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {IotDetails.buttons === "Yes" &&
                            <Row>
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginTop: '0.5rem', paddingBottom: '10px' }}>
                                        <textarea
                                            value={IotDetails.btnDetails}
                                            type="text"
                                            name="btnDetails"
                                            placeholder="Enter button details"
                                            className="textBoxIot2"
                                            style={{
                                                border: '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',

                                                padding: '3px 3px 3px 15px',
                                                marginTop: "0.3rem",
                                                maxLines: '20',
                                                borderRadius: "5px",

                                                width: "90%",
                                                resize: 'none'
                                            }}
                                            onChange={(e) => (setIotDetails((prev) => { return { ...prev, btnDetails: e.target.value } }))}
                                        />
                                    </div>
                                </Col>
                            </Row>}

                    </Col>

                    <Col sm={12} md={6} style={{ margin: '0 0 0 0', padding: '0 0 0 0' }} >
                        <Row className="margin-md">
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div className="margin" >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need an enclosure? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {['Yes', 'No'].map((need) => (
                                            <label className="text" key={need} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={need}
                                                    name="enclosure"
                                                    checked={IotDetails.enclosure === need}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {need}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '1rem' }}>
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need a custom PCB? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {['Yes', 'No'].map((need) => (
                                            <label className="text" key={need} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={need}
                                                    name="pcb"
                                                    checked={IotDetails.pcb === need}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {need}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleNextButton} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default IotDeviceSection_4;