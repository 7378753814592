import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import NavBar from "./NavBar";
import '../css/FirstPage.css'
import { useNavigate } from "react-router-dom";
import helpIcon from '../assets/help.svg'
import "./Bfutton.css";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';

function IotDeviceSection_3() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const [iotNameheading, setIotNameHeading] = useState(() => {
        const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
        if (componentSelected === 'Complete System') {
            const savedName = sessionStorage.getItem('permanentdetails');
            return savedName ? `Iot device ${(JSON.parse(savedName)).iotname} (Part 3)` : "Iot device";
        } else {
            return "Iot device"
        }

    });

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
    const [isShowStorageOptions, setIsShowStorageOptions] = useState(false);
    const [isReShowOptions, setIsReShowOptions] = useState(false);
    const [StorageDetails, setStorageDetails] = useState(() => {
        const savedStorageDetails = sessionStorage.getItem('storagedetails');
        return savedStorageDetails ? JSON.parse(savedStorageDetails) : {
            specific_hardware: '',
            type: 'SQL',
            backend: 'No',
            technology: 'Nodejs',
            hosting: 'Amazon',
            sameForAll: 'Yes'
        }
    });

    useEffect(() => {
        if (componentSelected === 'Complete System') {
            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                setIsShowStorageOptions(true);
            } else {
                setIsShowStorageOptions(false);
            }
        }

    }, [componentSelected]);



    useEffect(() => {
        sessionStorage.setItem('storagedetails', JSON.stringify(StorageDetails))
    }, [StorageDetails]);

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const storage = ["SQL", "NoSQL(Firebase, mongoDB, DynamoDB)", "No Need for Data Storage", "Other"]



    function handleNextButton() {

        if (StorageDetails.type !== 'No Need for Data Storage') {
            if (StorageDetails.type === 'Other') {
                alert("Please give a data storage type");
                return;
            }
        }

        if (componentSelected === 'Complete System') {

            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                sessionStorage.setItem('permanentdetails', JSON.stringify(
                    {
                        ...permanentdetails,
                        sameBackendStorage: StorageDetails.sameForAll
                    }
                ))
            }

        }

        console.log(JSON.parse(sessionStorage.getItem('storagedetails')));
        navigate('/iotdevicesection_4');
    };

    function handleRadioChangeType(event) {
        const { value } = event.target;
        setStorageDetails((prev) => ({ ...prev, type: value }));

        if (value === "No Need for Data Storage") {
            setStorageDetails((prev) => ({ ...prev, backend: 'No', }));
        }
    };

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setStorageDetails((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingBottom: '1rem',
                        backgroundColor: "#ffffff",
                    }}
                >

                    <Col sm={12} md={6} style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>{iotNameheading}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '0.5rem', marginBottom: '0.3rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Are there any specific hardware components you want to use?
                                    </label>
                                    <br />
                                    <textarea
                                        value={StorageDetails.specific_hardware}
                                        type="text"
                                        name="specific_hardware"
                                        placeholder="Enter specific hardware features"
                                        className="textBoxIot2"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setStorageDetails((prev) => { return { ...prev, specific_hardware: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div  >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        What is the Data Storage type needed? <span style={{ color: "red" }}>*</span>
                                        <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                            <img className="plusImage" src={helpIcon} alt="help" style={{
                                                paddingLeft: '3px',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span>
                                    </label>
                                    <div style={{ marginTop: '7px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {storage.map((type) => (
                                            <label className="text" key={type} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={type}
                                                    name="type"
                                                    checked={type === "Other" ? (StorageDetails.type !== 'SQL' && StorageDetails.type !== 'NoSQL(Firebase, mongoDB, DynamoDB)' && StorageDetails.type !== 'No Need for Data Storage' ) : (StorageDetails.type === type)}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChangeType}
                                                />
                                                {type}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {
                            (StorageDetails.type !== 'SQL' && StorageDetails.type !== 'NoSQL(Firebase, mongoDB, DynamoDB)' && StorageDetails.type !== 'No Need for Data Storage') &&
                            <Row>
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginTop: '0.5rem', marginBottom: '20px' }}>
                                        <input
                                            type="text"
                                            name="timeToLast"
                                            placeholder="Enter storage type"
                                            className="inputBox"
                                            value={StorageDetails.type==="Other"?"":StorageDetails.type}
                                            style={{
                                                border: '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',

                                                padding: '3px 3px 3px 15px',
                                                marginLeft: '0.3rem',
                                                borderRadius: "5px",

                                                width: "70%",
                                            }}
                                            onChange={(e) => (setStorageDetails((prev) => { return { ...prev, type: e.target.value } }))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                        {
                            StorageDetails.type !== storage[2] &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginBottom: '20px', marginTop: '10px' }} >
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            Do you need a custom backend? <span style={{ color: "red" }}>*</span>
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer"><img src={helpIcon} alt="help"
                                                className="plusImage"
                                                style={{
                                                    paddingLeft: '3px',
                                                    cursor: 'pointer', verticalAlign: 'middle'
                                                }} /></a></span>
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Yes', 'No', 'Maybe'].map((backend) => (
                                                <label className="text" key={backend} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={backend}
                                                        name="backend"
                                                        checked={backend === "Yes" ? (StorageDetails.backend !== 'No' && StorageDetails.backend !== 'Maybe' && StorageDetails.backend !== '') : (StorageDetails.backend === backend)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {backend}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {
                            (StorageDetails.type !== storage[2] && componentSelected === "Complete System" && isShowStorageOptions) &&
                            <Row>
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginBottom: '20px' }} >
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            Should we use the same Backend and storage for all devices ? <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Yes', 'No'].map((sameForAll) => (
                                                <label className="text" key={sameForAll} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={sameForAll}
                                                        name="sameForAll"
                                                        checked={sameForAll === "Yes" ? (StorageDetails.sameForAll !== 'No' && StorageDetails.sameForAll !== '') : (StorageDetails.sameForAll === sameForAll)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {sameForAll}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>

                    <Col sm={12} md={6} >


                        {StorageDetails.backend === 'Yes' &&
                            <Row className="margin-md">
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginBottom: '10px' }} >
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            what is the technology that needs to be used? 
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Nodejs', 'Java', 'Python', 'PHP', 'Golang'].map((technology) => (
                                                <label className="text" key={technology} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={technology}
                                                        name="technology"
                                                        checked={StorageDetails.technology === technology}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {technology}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {
                            StorageDetails.backend === 'Yes' &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginBottom: '10px' }} >
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            What is the hosting platform you prefer? 
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Amazon', 'Microsoft Azure', 'Google Cloud platform', 'Heroku',].map((hosting) => (
                                                <label className="text" key={hosting} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={hosting}
                                                        name="hosting"
                                                        checked={StorageDetails.hosting === hosting}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {hosting}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleNextButton} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default IotDeviceSection_3;