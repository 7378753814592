import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import NavBar from "./NavBar";
import { useAsyncError, useNavigate } from "react-router-dom";
import { ref, set, update } from "firebase/database";
import { database } from "../firebase";
import helpIcon from '../assets/help.svg'
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';
import "./Bfutton.css";

const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function BatteryPage() {
    const handleBackClick = () => {
        navigate(-1);
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const [finishButtonText,setFinishButtonText]=useState('Next');

    useEffect(()=>{
        const enclosureSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).enclosure;
        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));
        if (componentSelected === 'Complete System') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";

            if (enclosureSelected === 'Yes') {
                
            } else if (pcbSelected === 'Yes') {
               
            } else if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                   
                } else if (interfaceSelected === 'Web App') {
                   
                } else if (interfaceSelected === 'Touch Display') {
                    
                } else if (interfaceSelected === 'Non-Touch Display') {
                    
                }
            } else {
                setFinishButtonText('Finish');
            }

        } else {
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));

            if (enclosureSelected === 'Yes') {
                
            } else if (pcbSelected === 'Yes') {
                
            } else if (interfaceSelected === 'Mobile App') {
               
            } else if (interfaceSelected === 'Web App') {
                
            } else if (interfaceSelected === 'Touch Display') {
               
            } else if (interfaceSelected === 'Non-Touch Display') {
               
            } else {
                setFinishButtonText("Finish");
            }

        }
    },[]);

    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2)
        };

        emailjs.send(
            SERVICE_ID,
            TEMPLATE_ID,
            emailParams,
            PUBLIC_KEY
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const [changesAdded, setChangesAdded] = useState(false);
    const [BatteryDetails, setBatteryDetails] = useState(() => {
        const savedBatteryDetails = sessionStorage.getItem('batterydetails');
        return savedBatteryDetails ? JSON.parse(savedBatteryDetails) : {
            isRechargeable: 'No',
            indicatorNeed: 'No',
            chargingVoltCurrent: '',
            port: 'Micro usb',
            protection: 'Yes',
            type: 'Lithium-Ion (Li-ion)',
            timeToLast: '',
            fuelGueageNeed: 'No'
        }
    });



    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);


        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const [error, setError] = useState(
        {
            type: false
        }
    )

    useEffect(() => {
        sessionStorage.setItem('batterydetails', JSON.stringify(BatteryDetails));
    }, [BatteryDetails]);

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setBatteryDetails((prev) => ({ ...prev, [name]: value }));
    }

    function handleClickNext() {

        let newError = {};
        if (BatteryDetails.type === 'Other') {
            newError.type = true;
        }

        setError(newError);

        if (Object.values(newError).some((error) => error)) {
            return;
        }

        console.log(JSON.parse(sessionStorage.getItem('batterydetails')));

        const enclosureSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).enclosure;
        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));


        if (componentSelected === 'Complete System') {
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));

            if (enclosureSelected === 'Yes') {
                navigate('/enclosuredesignpage');
            } else if (pcbSelected === 'Yes') {
                navigate('/pcbdesignpage_1');
            } else if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                    navigate('/mobileapppage');
                } else if (interfaceSelected === 'Web App') {
                    navigate('/webapplicationpage');
                } else if (interfaceSelected === 'Touch Display') {
                    navigate('/touchdisplaypage');
                } else if (interfaceSelected === 'Non-Touch Display') {
                    navigate('/nontouchdisplaypage');
                }
            } else {
                const finalIotObject = {
                    iot_page_1: iot1,
                    iot_page_2: iot2,
                    iot_page_3: iot3,
                    iot_page_4: iot4,
                    iot_page_5: iot5,
                    ML_Energyconservation_Battery: iot6,
                    battery_details: BatteryDetails
                };

                let permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
                const iotDeviceKeyName = `iot_device_${permanentdetails.numberOfIotDevices}`
                let detailsSaved = {};

                let dateNow = 'date';
                if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                    dateNow = Date.now();
                    sessionStorage.setItem('permanentdetails', JSON.stringify(
                        {
                            ...permanentdetails,
                            dateNow: dateNow
                        }
                    ));
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    detailsSaved = {
                        personalDetails: personDetailsSaved,
                        component: componentSelected,
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    set(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === '1' || permanentdetails.numberOfIotDevices === '1') {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();
                        } else {
                            permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );
                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('batterydetails');
                            sessionStorage.removeItem('uiselected');
                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })

                } else {
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    dateNow = permanentdetails.dateNow;
                    detailsSaved = {
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    update(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === 1 || permanentdetails.numberOfIotDevices === 1) {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();

                        } else {
                            permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );

                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('batterydetails');
                            sessionStorage.removeItem('uiselected');
                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })

                }



            }
        } else {
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));

            if (enclosureSelected === 'Yes') {
                navigate('/enclosuredesignpage');
            } else if (pcbSelected === 'Yes') {
                navigate('/pcbdesignpage_1');
            } else if (interfaceSelected === 'Mobile App') {
                navigate('/mobileapppage');
            } else if (interfaceSelected === 'Web App') {
                navigate('/webapplicationpage');
            } else if (interfaceSelected === 'Touch Display') {
                navigate('/touchdisplaypage');
            } else if (interfaceSelected === 'Non-Touch Display') {
                navigate('/nontouchdisplaypage');
            } else {
                let finalIotObject = {
                    iot_page_1: iot1,
                    iot_page_2: iot2,
                    iot_page_3: iot3,
                    iot_page_4: iot4,
                    iot_page_5: iot5,
                    ML_Energyconservation_Battery: iot6,
                    battery_details: BatteryDetails,
                };
                const detailsSaved = {
                    personalDetails: personDetailsSaved,
                    component: componentSelected,
                    details: finalIotObject
                }
                const dbRef = ref(database, `${Date.now()}`);
                set(dbRef, detailsSaved).then(() => {
                    console.log("Saved to database");
                }).catch((error) => {
                    console.error("Error saving in database: ", error);
                })
                sendEmail(detailsSaved);
                sessionStorage.clear();
                setChangesAdded(true);
                handleClickOpen();
            }
        }
    }

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '1rem',
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={6} >

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>Battery</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Should the battery be rechargeable?
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Yes", "No"].map((isRechargeable) => (
                                            <label className="text" key={isRechargeable} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={isRechargeable}
                                                    name="isRechargeable"
                                                    checked={BatteryDetails.isRechargeable === isRechargeable}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {isRechargeable}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Should there be an indicator for battery charging status?
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Yes", "No"].map((indicatorNeed) => (
                                            <label className="text" key={indicatorNeed} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={indicatorNeed}
                                                    name="indicatorNeed"
                                                    checked={BatteryDetails.indicatorNeed === indicatorNeed}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {indicatorNeed}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need a fuel gauge to measure the remaining battery capacity?
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Yes", "No"].map((fuelGueageNeed) => (
                                            <label className="text" key={fuelGueageNeed} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={fuelGueageNeed}
                                                    name="fuelGueageNeed"
                                                    checked={BatteryDetails.fuelGueageNeed === fuelGueageNeed}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {fuelGueageNeed}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            paddingTop: '5px',
                                            marginRight: '6rem',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        What should be the charging voltage and current?
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="chargingVoltCurrent"
                                        placeholder="Enter voltage and current"
                                        className="inputBox"
                                        value={BatteryDetails.chargingVoltCurrent}
                                        style={{
                                            border: error.chargingVoltCurrent ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            marginTop: '10px',
                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '0.3rem',
                                            borderRadius: "5px",

                                            width: "70%",
                                        }}
                                        onChange={(e) => (setBatteryDetails((prev) => { return { ...prev, chargingVoltCurrent: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',

                                            paddingTop: '5px',
                                            marginRight: '6rem',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        How long the battery should last before charging?
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="timeToLast"
                                        placeholder="Enter time period"
                                        value={BatteryDetails.timeToLast}
                                        className="inputBox"
                                        style={{
                                            border: error.timeToLast ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            marginTop: '10px',
                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '0.3rem',
                                            borderRadius: "5px",

                                            width: "70%",
                                        }}
                                        onChange={(e) => (setBatteryDetails((prev) => { return { ...prev, timeToLast: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} >

                        <Row style={{ marginTop: '2rem' }}>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        What should be the charging port?
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Micro usb", "USB C", "Barrel jack"].map((port) => (
                                            <label className="text" key={port} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={port}
                                                    name="port"
                                                    checked={BatteryDetails.port === port}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {port}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Do you need Overcharge, over-discharge protection?
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Yes", "No", "Maybe"].map((protection) => (
                                            <label className="text" key={protection} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={protection}
                                                    name="protection"
                                                    checked={BatteryDetails.protection === protection}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {protection}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        What is the type of battery preferred?
                                        <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                            <img className="plusImage" src={helpIcon} alt="help" style={{
                                                paddingLeft: '3px',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span>
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                        {["Lithium-Ion (Li-ion)", "Lithium Polymer", "Coin Cell (Button Cell)", "Nickel-Cadmium (NiCd)", "Alkaline", "Other"].map((type) => (
                                            <label className="text" key={type} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={type}
                                                    name="type"
                                                    checked={type !== 'Other' ? BatteryDetails.type === type : (BatteryDetails.type !== 'Lithium-Ion (Li-ion)' && BatteryDetails.type !== 'Lithium Polymer' && BatteryDetails.type !== 'Coin Cell (Button Cell)' && BatteryDetails.type !== 'Nickel-Cadmium (NiCd)' && BatteryDetails.type !== 'Alkaline')}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {type}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {(BatteryDetails.type !== 'Lithium-Ion (Li-ion)' && BatteryDetails.type !== 'Lithium Polymer' && BatteryDetails.type !== 'Coin Cell (Button Cell)' && BatteryDetails.type !== 'Nickel-Cadmium (NiCd)' && BatteryDetails.type !== 'Alkaline') &&
                            <Row>
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginTop: '0.5rem', paddingBottom: '10px' }}>
                                        <input
                                            type="text"
                                            name="timeToLast"
                                            placeholder="Enter battery type"
                                            value={BatteryDetails.type === "Other" ? "" : BatteryDetails.type}
                                            className="inputBox"
                                            style={{
                                                border: error.type ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                fontFamily: 'poppins',

                                                padding: '3px 3px 3px 15px',
                                                marginLeft: '0.3rem',
                                                borderRadius: "5px",

                                                width: "70%",
                                            }}
                                            onChange={(e) => (setBatteryDetails((prev) => { return { ...prev, type: e.target.value } }))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                        <Button className="text" onClick={handleCancelClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> {finishButtonText} </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default BatteryPage;