import { Container, Row, Col } from "react-grid-system";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';
import "./Bfutton.css";

function CompleteSystemOverview() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        sessionStorage.setItem('startedComponentSelected', 'started')
    }, [])


    const personDetailsSaved = (sessionStorage.getItem('permanentdetails')) ? JSON.parse(sessionStorage.getItem('permanentdetails')).numberOfIotDevices : "1";
    const numberOfIotDevicesTotal = (sessionStorage.getItem('overviewcompletedetails')) ? JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices : "1";

    const [overviewDetails, setOverViewDetails] = useState(() => {
        const savedCompleteOverview = sessionStorage.getItem('overviewcompletedetails');
        return savedCompleteOverview ? JSON.parse(savedCompleteOverview) : {
            title: '',
            specification: '',
            numberOfIotDevices: '1',
            overview: ''
        };
    });

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    useEffect(() => {
        sessionStorage.setItem('overviewcompletedetails', JSON.stringify(overviewDetails));
    }, [overviewDetails]);

    useEffect(() => {
        sessionStorage.setItem('devicedetailsselect', 'learn');
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const [errordetail, setError] = useState({
        title: false,
        specification: false,
        numberOfIotDevices: false,
        overview: false
    });

    function handleNextButton() {

        let newErrors = {
            title: !overviewDetails.title,
            specification: !overviewDetails.specification,
            numberOfIotDevices: !overviewDetails.numberOfIotDevices || !/^[1-9]\d*$/.test(overviewDetails.numberOfIotDevices),
            overview: !overviewDetails.overview
        };


        setError(newErrors);


        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        sessionStorage.setItem('permanentdetails', JSON.stringify(
            {
                numberOfIotDevices: overviewDetails.numberOfIotDevices,
                iotname: 1
            }
        ))

        console.log(JSON.parse(sessionStorage.getItem('overviewcompletedetails')));
        console.log(JSON.parse(sessionStorage.getItem('permanentdetails')));
        navigate('/iotdevicesection_1');
    }

    const [iotDeviceNumber, setiotDeviceNumber] = useState(
        () => {
            let arrayNumber = [];
            for (let i = 1; i <= 20; i++) {
                arrayNumber.push(i);
            }
            return arrayNumber;
        }
    );

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row className="first-row" style={{ paddingLeft: "1rem", paddingRight: "2rem", backgroundColor: "#ffffff" }}>
                    <Col sm={12} md={6}>
                        <Row >
                            <Col sm={12} md={12} style={{ margin: '0' }}>
                                <div style={{ marginTop: '2rem' }}>
                                    <label className="text" style={{ fontWeight: '500', marginBottom: "0.5rem", fontFamily: "poppins", color: "rgba(71, 85, 105, 1)" }}>
                                        Project Title<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="title"
                                        className="inputBox"
                                        style={{
                                            border: errordetail.title ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 15px',
                                            marginTop: "0.3rem",
                                            borderRadius: "5px",
                                            width: "90%",
                                        }}
                                        value={overviewDetails.title}
                                        onChange={(e) => {
                                            setOverViewDetails((prevState) => ({ ...prevState, title: e.target.value }));
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={12} md={12}>
                                <div>
                                    <label className="text" style={{ fontWeight: '500', marginBottom: "0.5rem", fontFamily: "poppins", color: "rgba(71, 85, 105, 1)" }}>
                                        What specifications do you need in your IoT system?<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <textarea
                                        name="specification"
                                        className="textBox"
                                        style={{
                                            border: errordetail.specification ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 15px',
                                            marginTop: "0.3rem",
                                            borderRadius: "5px",
                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        value={overviewDetails.specification}
                                        onChange={(e) => {
                                            setOverViewDetails((prevState) => ({ ...prevState, specification: e.target.value }));
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12}>
                                <div style={{ paddingTop: '20px' }}>
                                    <label className="text" style={{ fontWeight: '500', marginBottom: "0.5rem", fontFamily: "poppins", color: "rgba(71, 85, 105, 1)" }}>
                                        How many different IoT devices are in the system? <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <select name="numberOfIotDevices"
                                        disabled={personDetailsSaved !== numberOfIotDevicesTotal}
                                        className="inputBox"
                                        value={overviewDetails.numberOfIotDevices}
                                        onChange={(e) => {
                                            setOverViewDetails((prevState) => ({ ...prevState, numberOfIotDevices: e.target.value }));
                                        }}
                                        style={{
                                            border: errordetail.numberOfIotDevices ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 15px',
                                            marginTop: "0.3rem",
                                            borderRadius: "5px",
                                            width: "90%",
                                        }}>
                                        {iotDeviceNumber.map((iotDeviceNumber, index) => (
                                            <option key={index} value={iotDeviceNumber}>
                                                {iotDeviceNumber}
                                            </option>

                                        ))}

                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} style={{ marginTop: '2rem' }}>
                        <Row>
                            <Col sm={12} md={12} style={{ margin: '0' }}>
                                <div>
                                    <label className="text" style={{ fontWeight: '500', marginBottom: "0.5rem", fontFamily: "poppins", color: "rgba(71, 85, 105, 1)" }}>
                                        Brief Overview<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <br />
                                    <textarea
                                        name="overview"
                                        className="textBox"
                                        style={{
                                            border: errordetail.overview ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 15px',
                                            marginTop: "0.3rem",
                                            borderRadius: "5px",
                                            width: "100%",
                                            resize: 'none'
                                        }}
                                        value={overviewDetails.overview}
                                        onChange={(e) => {
                                            setOverViewDetails((prevState) => ({ ...prevState, overview: e.target.value }));
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div>
                <Row>
                    <Col sm={12} md={12} className="button-container">
                        <Button className="text" onClick={handleCancelClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleNextButton} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px',
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default CompleteSystemOverview;