import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import "../css/FirstPage.css"
import "../css/ComponentSelectPage.css"
import { Button } from "@mui/material-next";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import "./Bfutton.css";

function ProductInformationSelectOption() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [buttonText, setButtonText] = useState(() => {
        const savedSelection = JSON.parse(sessionStorage.getItem('selectiondetails') || '{}');
        return savedSelection.selection ? savedSelection.selection : '';
    });

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const savedComponent = JSON.parse(sessionStorage.getItem('componentdetails') || '{}');

    useEffect(() => {
        sessionStorage.setItem('selectiondetails', JSON.stringify({ selection: buttonText }));
    }, [buttonText])

    const [buttonPressed, setButtonpressed] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
            // // Optionally add a warning for unsaved changes
            // event.preventDefault(); // For most browsers
            // event.returnValue = '';  // For Chrome
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);


    const buttonTextArray = ["Learn While Ordering", "Direct Requirement Send"];

    function handleClick() {
        if (!buttonText) {
            handleClickOpen();
            setButtonpressed(false);
        } else {
            setButtonpressed(true);
            if (buttonText === buttonTextArray[1]) {
                navigate('/uploadfilesdirectlypage');
            } else if (buttonText === buttonTextArray[0]) {
                switch (savedComponent.component) {
                    case 'Complete System': {
                        navigate('/completesystemoverview');
                        break;
                    };
                    case 'IOT device': {
                        navigate('/iotdevicesection_1');
                        break;
                    };
                    case 'Mobile App': {
                        navigate('/mobileapppage');
                        break;
                    };
                    case 'Web dashboard': {
                        navigate('/webapplicationpage');
                        break;
                    };
                    case 'PCB design': {
                        navigate('/pcbdesignpage_0');
                        break;
                    };
                    default: {
                        alert("An error occured");
                        break;
                    };

                }
            }

        }
    }

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={2} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={12}>
                        <Row style={{ marginTop: '2rem' }}>
                            <Col sm={12} md={12}>
                                <p
                                    className="text"
                                    style={{
                                        fontFamily: 'poppins',
                                        color: buttonPressed ? 'rgba(71, 85, 105, 1)' : 'red'
                                    }}>Choose a Requirement Gathering Method <span style={{ color: 'red' }}>*</span></p>
                            </Col>
                        </Row>

                        <Row style={{ justifyContent: 'space-evenly' }} >
                            {
                                buttonTextArray.map((buttonTextnew, index) => (
                                    <Col key={index} sm={12} md={12 / 2}
                                        style={{
                                            marginBottom: '20px',
                                        }}>
                                        <Button
                                            className="text"
                                            style={{
                                                borderRadius: '6px',
                                                minWidth: '100%',
                                                backgroundColor: buttonTextArray[index] === buttonText ? 'rgba(71, 85, 105, 1)' : 'rgba(156, 136, 255, 1)',
                                                color: 'white',
                                                fontFamily: 'poppins',
                                            }}
                                            onClick={() => setButtonText(buttonTextnew)}
                                        >
                                            {buttonTextnew}
                                        </Button>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>


                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClick} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningAmberIcon style={{ marginRight: '0.5rem', color: 'white' }} />
                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Alert
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Please select the required inputs to proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: '700',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default ProductInformationSelectOption;