import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ComponentSelectPage from './components/ComponentSelectPage';
import FirstPage from './components/FirstPage';
import ProductInformationSelectOption from './components/ProductInformationSelectOption';
import UploadFilesDirectlyPage from './components/UploadFilesDirectlyPage';
import CompleteSystemOverview from './components/CompleteSystemOverview';
import IotDeviceSection_1 from './components/IotDeviceSection_1';
import PcbDesignPage_1 from './components/PcbDesignPage_1';
import PcbDesignPage_2 from './components/PcbDesignPage_2';
import IotDeviceSection_3 from './components/IotDeviceSection_3';
import IotDeviceSection_2 from './components/IotDeviceSection_2';
import IotDeviceSection_4 from './components/IotDeviceSection_4';
import IotDeviceSection_5 from './components/IotDeviceSection_5';
import BatteryPage from './components/BatteryPage';
import EnclosureDesignPage from './components/EnclosureDesignPage';
import MlPageEnergy from './components/MlPageEnergy';
import MobileAppPage from './components/MobileAppPage';
import NonTouchDisplayPage from './components/NonTouchDisplayPage';
import TouchDisplayPage from './components/TouchDisplayPage';
import WebApplicationPage from './components/WebApplicationPage';
import PcbDesignPage_0 from './components/PcbDesignPage_0'
import Layout from './components/Layout';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = () => {
      if (location.pathname !== '/') {
        navigate('/', { replace: true });
      }
    };

    // Listen to the back button event
    window.addEventListener('popstate', handlePopState);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location, navigate]);
  return (
    <Layout>
    <Routes>
      {/* Define your app's routes here */}
      <Route path="/" element={<FirstPage />} />
      <Route path="/componentselectpage" element={<ComponentSelectPage />} />
      <Route path="/productinformationselectoption" element={<ProductInformationSelectOption />} />
      <Route path="/uploadfilesdirectlypage" element={<UploadFilesDirectlyPage />} />
      <Route path="/completesystemoverview" element={<CompleteSystemOverview />} />
      <Route path="/iotdevicesection_1" element={<IotDeviceSection_1 />} />
      <Route path="/iotdevicesection_2" element={<IotDeviceSection_2 />} />
      <Route path="/iotdevicesection_3" element={<IotDeviceSection_3 />} />
      <Route path="/iotdevicesection_4" element={<IotDeviceSection_4 />} />
      <Route path="/iotdevicesection_5" element={<IotDeviceSection_5 />} />
      <Route path="/pcbdesignpage_0" element={<PcbDesignPage_0 />} />
      <Route path="/pcbdesignpage_1" element={<PcbDesignPage_1 />} />
      <Route path="/pcbdesignpage_2" element={<PcbDesignPage_2 />} />
      <Route path="/batterypage" element={<BatteryPage />} />
      <Route path="/enclosuredesignpage" element={<EnclosureDesignPage />} />
      <Route path="/mlpageenergy" element={<MlPageEnergy />} />
      <Route path="/mobileapppage" element={<MobileAppPage />} />
      <Route path="/nontouchdisplaypage" element={<NonTouchDisplayPage />} />
      <Route path="/productinformationselectoption" element={<ProductInformationSelectOption />} />
      <Route path="/touchdisplaypage" element={<TouchDisplayPage />} />
      <Route path="/uploadfilesdirectlypage" element={<UploadFilesDirectlyPage />} />
      <Route path="/webapplicationpage" element={<WebApplicationPage />} />
      

    </Routes>
    </Layout>
  );
}

export default App;