import { Button } from "@mui/material-next";
import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import "../css/FirstPage.css";
import "../css/Bfutton.css";
import "../index.css";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import countriesData from "../assets/countries.json"; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';

function FirstPage() {

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.clear();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };


    const handleBackClick = () => {
        sessionStorage.clear();
        window.location.href = 'https://protonest.co/';
    };

    useEffect(() => {
        if (!(sessionStorage.getItem('componentdetails'))) {
            sessionStorage.clear();
        } else if (JSON.parse(sessionStorage.getItem('componentdetails')).component === '' && !(JSON.parse(sessionStorage.getItem('personaldetails')))) {
            sessionStorage.clear();
        }
    }, []);

    const [countryid, setCountryid] = useState(0);
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    useEffect(() => {
        setCountriesList(countriesData);
    }, []);
    const [isFocused, setIsFocused] = useState(false);


    const [personalDetails, setPersonalDetails] = useState(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        return savedDetails ? JSON.parse(savedDetails) : {
            company_Name: '',
            person_Name: '',
            person_Email: '',
            person_Mobile: '',
            contact_through_email: false,
            contact_through_phone: false,
            person_country: 'Sri Lanka',
        };
    });

    const [countryDetails, setCountryDetails] = useState(() => {
        const savedDetails = sessionStorage.getItem('countrydetails');
        return savedDetails ? JSON.parse(savedDetails) : 209;
    });

    const [fontSize, setFontSize] = useState('80%');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1800) {
                setFontSize('80%'); 
            } else {
                setFontSize('100%'); 
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            setPersonalDetails({
                company_Name: '',
                person_Name: '',
                person_Email: '',
                person_Mobile: '',
                contact_through_email: false,
                contact_through_phone: false,
                person_country: 'Sri Lanka',
            });
        }

        const savedCountryDetails = sessionStorage.getItem('countrydetails');
        if (!savedCountryDetails) {
            setCountryDetails(209);
        }

    }, []);


    useEffect(() => {
        sessionStorage.setItem('personaldetails', JSON.stringify(personalDetails));

    }, [personalDetails]);

    useEffect(() => {
        sessionStorage.setItem('countrydetails', JSON.stringify(countryDetails));
    }, [countryDetails]);

    const [errordetail, setError] = useState({
        person_Name: false,
        person_Email: false,
        person_Mobile: false,
        isChecked: false
    });

    const navigate = useNavigate();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    function handleNextButton() {
        console.log(JSON.parse(sessionStorage.getItem('personaldetails')));
        let newErrors = {};
        newErrors.person_Name = !personalDetails.person_Name;
        newErrors.person_Email = !personalDetails.person_Email;

        if (!personalDetails.person_Email || !emailRegex.test(personalDetails.person_Email)) {
            newErrors.person_Email = true;
        } else {
            newErrors.person_Email = false;
        }



        if ((!personalDetails.contact_through_email && !personalDetails.contact_through_phone) || (personalDetails.contact_through_email && personalDetails.contact_through_phone)) {
            newErrors.isChecked = true;
        } else {
            newErrors.isChecked = false;
        }

        if (!newErrors.isChecked) {
            if (personalDetails.contact_through_email) {
                newErrors.person_Mobile = false;
            }
            if (personalDetails.contact_through_phone) {
                if ((personalDetails.person_Mobile).toString().length < 7) {
                    newErrors.person_Mobile = true;
                } else {
                    newErrors.person_Mobile = false;
                }
            }
        }
        setError(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        console.log(JSON.parse(sessionStorage.getItem('personaldetails')));
        navigate('/componentselectpage');

    }

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={0} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={12} style={{ paddingLeft: '20px' }}>
                        <form>
                            <div style={{ margin: '0', padding: '0' }}>
                                <Row className="rowClass" style={{ marginLeft: '0', paddingLeft: '0', paddingTop: '20px', paddingBottom: '20px' }}>
                                    <Col sm={12} md={7} style={{ marginBottom: "20px" }}>
                                        <div>
                                            <label
                                                className="text"
                                                style={{
                                                    fontWeight: '500',
                                                    marginBottom: "0.5rem",
                                                    fontFamily: "poppins",
                                                    color: "rgba(71, 85, 105, 1)",
                                                }}
                                            >
                                                Full Name<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <br />
                                            <input
                                                className="inputBox"
                                                type="text"
                                                name="person_Name"
                                                placeholder="Full name"
                                                value={personalDetails.person_Name || ""}
                                                style={{
                                                    border: errordetail.person_Name ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                    fontFamily: 'poppins',
                                                    padding: '3px 3px 3px 15px',
                                                    marginTop: "0.3rem",
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                }}
                                                onChange={(e) => {

                                                    setPersonalDetails((prevState) => ({ ...prevState, person_Name: e.target.value, }));
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={5} style={{ marginBottom: "20px" }}>
                                        <div>
                                            <label
                                                className="text"
                                                style={{
                                                    fontWeight: '500',
                                                    marginBottom: "0.5rem",
                                                    fontFamily: "poppins",
                                                    color: "rgba(71, 85, 105, 1)",
                                                }}
                                            >
                                                Company Name
                                            </label>
                                            <br />
                                            <input
                                                type="text"
                                                name="company_Name"
                                                placeholder="Company name"
                                                value={personalDetails.company_Name}
                                                className="inputBox"
                                                style={{
                                                    border: '1px solid rgba(203, 213, 225, 0.7)',
                                                    fontFamily: 'poppins',
                                                    padding: '3px 3px 3px 15px',
                                                    marginTop: "0.3rem",
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                }}
                                                onChange={(e) => {

                                                    setPersonalDetails((prevState) => ({
                                                        ...prevState, company_Name:
                                                            e.target.value
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="rowClass" style={{ marginLeft: '0', paddingLeft: '0', paddingTop: '0', paddingBottom: '20px' }}>
                                    <Col sm={12} md={4} style={{ marginBottom: "20px" }}>
                                        <div>
                                            <label
                                                className="text"
                                                style={{
                                                    fontWeight: '500',
                                                    marginBottom: "0.5rem",
                                                    fontFamily: "poppins",
                                                    color: "rgba(71, 85, 105, 1)",
                                                }}
                                            >
                                                Email<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <br />
                                            <input
                                                className="inputBox"
                                                type="email"
                                                name="person_Email"
                                                placeholder="Email"
                                                value={personalDetails.person_Email}
                                                style={{
                                                    border: errordetail.person_Email ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)',
                                                    fontFamily: 'poppins',
                                                    padding: '3px 3px 3px 15px',
                                                    marginTop: "0.3rem",
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                }}
                                                onChange={(e) => {

                                                    setPersonalDetails((prevState) => ({ ...prevState, person_Email: e.target.value }));

                                                }} />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={4} style={{ marginBottom: "20px" }}>
                                        <div>
                                            <label
                                                className="text"
                                                style={{
                                                    fontWeight: '500',
                                                    marginBottom: "0.5rem",
                                                    fontFamily: "poppins",
                                                    color: "rgba(71, 85, 105, 1)",
                                                }}
                                            >
                                                Country
                                            </label>
                                            <br />
                                            <div className="inputBoxDiv" style={{ width: '104%', margin: '0 0 0 0' }}>
                                                <select
                                                    value={countryDetails}
                                                    onChange={(e) => {
                                                        const country = stateList[e.target.value]; 
                                                        setCountryid(country);
                                                        setCountryDetails(e.target.value);
                                                        sessionStorage.setItem('countrydetails', JSON.stringify(countryDetails));
                                                        setPersonalDetails((prevState) => ({ ...prevState, person_country: countriesList[e.target.value].name }));
                                                    }}
                                                    className="inputBoxSelect"
                                                    style={{
                                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                                        fontFamily: 'poppins',
                                                        padding: '3px 3px 3px 15px',
                                                        marginTop: "0.3rem",
                                                        borderRadius: "5px",
                                                        width: "100%",

                                                    }}
                                                >
                                                    {countriesList.map((item, index) => (
                                                        <option key={index} value={index}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={4} style={{ marginBottom: "20px" }}>
                                        <div>
                                            <label
                                                className="text"
                                                style={{
                                                    fontWeight: '500',
                                                    marginBottom: "0.5rem",
                                                    fontFamily: "poppins",
                                                    color: "rgba(71, 85, 105, 1)",
                                                }}
                                            >
                                                Mobile No
                                            </label>
                                            <br />
                                            <div className="inputBoxDiv" style={{ width: '104%', margin: '0 0 0 0' }}>
                                                <PhoneInput
                                                    country={"eg"}
                                                    enableSearch={true}
                                                    value={personalDetails.person_Mobile}
                                                    onChange={(e, country) => {
                                                        setPersonalDetails((prevState) => ({ ...prevState, person_Mobile: e }));
                                                    }}
                                                    containerStyle={
                                                        {
                                                            width: '100%',
                                                            height: '100%',
                                                            marginTop: "0.3rem",
                                                            border: (errordetail.person_Mobile ? '1px solid red' : '1px solid rgba(203, 213, 225, 0.7)'),
                                                            borderRadius: "5px",
                                                            padding: '0 0 0 0',
                                                            boxShadow: 'none'
                                                        }

                                                    }

                                                    inputStyle={{
                                                        width: '100%',
                                                        height: '100%',
                                                        fontFamily: 'poppins',
                                                        fontWeight: '100',
                                                        border: 'none',
                                                        fontSize: fontSize,
                                                        boxShadow: 'none', 
                                                        outline: 'none'
                                                    }}
                                                    onFocus={() => setIsFocused(true)}   
                                                    onBlur={() => setIsFocused(false)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="rowClass" style={{ marginLeft: '0', paddingLeft: '0', paddingTop: '0px', paddingBottom: '20px' }} >
                                    <Col sm={12} md={12} style={{ marginBottom: "20px" }}>
                                        <div >
                                            <label
                                                className="text"
                                                style={{
                                                    fontFamily: "poppins",
                                                    color: "rgba(71, 85, 105, 1)",

                                                }}
                                            >
                                                Preferred Method of Communication (select one)
                                                <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <br />
                                            <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                                                <label className="text" style={{ marginRight: '1.5rem', color: 'rgba(71, 85, 105, 1)', fontFamily: 'poppins', display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className={errordetail.isChecked ? 'error_checked' : ''}
                                                        type="checkbox"
                                                        name="phoneButton"
                                                        checked={personalDetails.contact_through_phone}
                                                        style={{

                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={(e) => {
                                                            setPersonalDetails((prevState) => ({ ...prevState, contact_through_phone: e.target.checked,contact_through_email : false }));
                                                        }}
                                                    />
                                                    Phone
                                                </label>

                                                <label className="text" style={{ color: 'rgba(71, 85, 105, 1)', fontFamily: 'poppins', display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className={errordetail.isChecked ? 'error_checked' : ''}
                                                        type="checkbox"
                                                        name="emailButton"
                                                        checked={personalDetails.contact_through_email}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={(e) => {
                                                            setPersonalDetails((prevState) => ({
                                                                ...prevState, contact_through_email: e.target.checked, contact_through_phone: false
                                                            }))
                                                        }}
                                                    />
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </form>
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                        <Button className="text" onClick={handleCancelClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleNextButton} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', 
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            
        </Container>
    );
}

export default FirstPage;
