import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import NavBar from "./NavBar";
import '../css/FirstPage.css'
import { useNavigate } from "react-router-dom";
import helpIcon from '../assets/help.svg'
import "./Bfutton.css";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';

function IotDeviceSection_5() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const [iotNameheading, setIotNameHeading] = useState(() => {
        const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
        if (componentSelected === 'Complete System') {
            const savedName = sessionStorage.getItem('permanentdetails');
            return savedName ? `Iot device ${(JSON.parse(savedName)).iotname} (Part 5)` : "Iot device";
        } else {
            return "Iot device"
        }

    });

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
    const [isShowInterfaceOptions, setIsShowInterfaceOptions] = useState(false);
    const [isShowIntegrateOptions, setIsShowIntegrateOptions] = useState(false);
    const [isReshowInterface, setIsReShowInterface] = useState(true);
    const [isReshowIntegrate, setIsReShowIntegrate] = useState(true);
    const numberOfIotDevicesTotal = sessionStorage.getItem('overviewcompletedetails') ? JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices : "1";
    useEffect(() => {
        if (componentSelected === 'Complete System') {
            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                setIsShowInterfaceOptions(true);
                setIsShowIntegrateOptions(true);
            }
        }

    }, [componentSelected]);

    useEffect(() => {
        if (componentSelected === 'Complete System') {
            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if (permanentdetails.numberOfIotDevices < numberOfIotDevicesTotal) {

                if (permanentdetails.singleapp === 'No') {
                    setIsReShowInterface(true);
                } else {
                    setIsReShowInterface(false);
                }

                if (permanentdetails.integrateForAll === 'No') {
                    setIsReShowIntegrate(true);
                } else {
                    setIsReShowIntegrate(false);
                }

            }
        }

    }, [componentSelected]);

    const [IotDetails, setIotDetails] = useState(() => {
        const savedDetails = sessionStorage.getItem('iotchoices_2');
        return savedDetails ? JSON.parse(savedDetails) : {
            interface: 'No User interface',
            isSingleInterface: 'Yes',
            system: 'No need',
            selectedForAll: 'Yes',
        }
    });

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
            // // Optionally add a warning for unsaved changes
            // event.preventDefault(); // For most browsers
            // event.returnValue = '';  // For Chrome
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('iotchoices_2', JSON.stringify(IotDetails));
    }, [IotDetails]);


    useEffect(() => {

        if (sessionStorage.getItem('uiselected')) {

            if (IotDetails.enclosure === 'Web App') {
                sessionStorage.setItem('uiselected', 'web');
            } else if (IotDetails.interface === 'Mobile App') {
                sessionStorage.setItem('uiselected', 'mobile');
            } else if (IotDetails.interface === 'Touch Display') {
                sessionStorage.setItem('uiselected', 'td');
            } else if (IotDetails.interface === 'Non-Touch Display') {
                sessionStorage.setItem('uiselected', 'ntd');
            } else {
                sessionStorage.removeItem('uiselected');
            }
        }


    }, [IotDetails]);


    function handleClickNext() {

        if (componentSelected === 'Complete System') {

            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
            if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                sessionStorage.setItem('permanentdetails', JSON.stringify(
                    {
                        ...permanentdetails,
                        singleapp: IotDetails.isSingleInterface,
                        integrateForAll: IotDetails.selectedForAll
                    }
                ))
            }

        }
        navigate('/MlPageEnergy');
    }

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setIotDetails((prev) => ({ ...prev, [name]: value }));
    }

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={3} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingBottom: '1rem',
                        backgroundColor: "#ffffff",
                    }}
                >

                    <Col sm={12} md={6} style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>{iotNameheading}</p>
                                </div>
                            </Col>
                        </Row>

                        {isReshowInterface &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div className="margin" >
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            User Interface <span style={{ color: "red" }}>*</span>
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                                <img className="plusImage" src={helpIcon} alt="help" style={{
                                                    paddingLeft: '3px',
                                                    cursor: 'pointer', verticalAlign: 'middle'
                                                }} /></a></span>
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Mobile App', 'Web App', 'Touch Display', 'Non-Touch Display', 'No User interface'].map((interfaceIot) => (
                                                <label className="text" key={interfaceIot} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={interfaceIot}
                                                        name="interface"
                                                        checked={IotDetails.interface === interfaceIot}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {interfaceIot}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {((IotDetails.interface === 'Mobile App' || IotDetails.interface === 'Web App') && componentSelected === 'Complete System' && isShowInterfaceOptions && (numberOfIotDevicesTotal !== '1')) &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div style={{ marginBottom: '1rem' }}>
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            Do you need a single {IotDetails.interface} for all IoT devices?
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Yes', 'No'].map((interfaceSingle) => (
                                                <label className="text" key={interfaceSingle} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={interfaceSingle}
                                                        name="isSingleInterface"
                                                        checked={IotDetails.isSingleInterface === interfaceSingle}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {interfaceSingle}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }

                    </Col>

                    <Col sm={12} md={6} style={{ margin: '0 0 0 0', padding: '0 0 0 0' }} >

                        {isReshowIntegrate &&
                            <Row className="margin-md">
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div className="margin"  >
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            Are there any existing systems or platforms that your IoT solution needs to integrate with?
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                                <img className="plusImage" src={helpIcon} alt="help" style={{
                                                    paddingLeft: '3px',
                                                    cursor: 'pointer', verticalAlign: 'middle'
                                                }} /></a></span>
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Node-RED', 'ThingSpeak', 'Grafana', 'No need'].map((systemMap) => (
                                                <label className="text" key={systemMap} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={systemMap}
                                                        name="system"
                                                        checked={IotDetails.system === systemMap}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {systemMap}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>}
                        {(IotDetails.system !== 'No need' && IotDetails.system !== '' && componentSelected === 'Complete System' && isShowIntegrateOptions && (numberOfIotDevicesTotal !== '1')) &&
                            <Row >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                    <div className="margin">
                                        <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                            `Do you need {IotDetails.system} for all IoT devices?`
                                        </label>
                                        <div style={{ marginTop: '7px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {['Yes', 'No'].map((systemNeed) => (
                                                <label className="text" key={systemNeed} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={systemNeed}
                                                        name="selectedForAll"
                                                        checked={IotDetails.selectedForAll === systemNeed}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {systemNeed}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default IotDeviceSection_5;