import { Container, Col, Row } from "react-grid-system";
import NavBar from "./NavBar";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import helpIcon from '../assets/help.svg'
import "./Bfutton.css";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';

function PcbDesignPage_0() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    useEffect(() => {
        sessionStorage.setItem('devicedetailsselect', 'learn');
    }, []);
    useEffect(() => {
        sessionStorage.setItem('startedComponentSelected', 'started')
    }, [])
    const [designDetails, setDesignDetails] = useState(() => {
        const savedpcbdetails = sessionStorage.getItem('pcbdetailssaved');
        return savedpcbdetails ? JSON.parse(savedpcbdetails) : {
            size_Pcb: '',
            layer_number: '',
            inBuilt_details: '',
            special_req: 'Flexible PCBs',
            power_req: '',
            ic_prefer: '',
            connector_prefer: '',
            indicators_req: '',
            vendors_prefer: '',
            placement_prefer: '',
            cost_constraint: '',
            output_req: ['Gerber files'],
            assembly_req: '',
            testing_req: '',
            certifications: '',
            other_details: '',
            file_formats: '',
            functionalities_of_pcb: ''
        }
    });

    useEffect(() => {
        sessionStorage.setItem('pcbdetailssaved', JSON.stringify(designDetails));
    }, [designDetails])

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
            // // Optionally add a warning for unsaved changes
            // event.preventDefault(); // For most browsers
            // event.returnValue = '';  // For Chrome
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    function handleClickNext() {
        navigate('/pcbdesignpage_1', { state: { detailsObject: designDetails } });
    }

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={5} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '1rem',

                        paddingLeft: "2rem",
                        paddingRight: "2rem",

                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={12} >

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>PCB Design (Functions)</p>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        What should be the functionalities of the PCB?
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        name="functionalities_of_pcb"
                                        value={designDetails.functionalities_of_pcb}
                                        placeholder="Enter functions of PCB"
                                        className="textBox11"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, functionalities_of_pcb: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> Next </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default PcbDesignPage_0;