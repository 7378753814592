import { Row, Col } from "react-grid-system";
import "../css/FirstPage.css";
import "../index.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function NavBar({ pageNo }) {

    const components = [
        { cName: 'Personal Details' },
        { cName: 'Components to Implement' },
        { cName: 'Product Information' },
        { cName: 'Device Details' },
        { cName: '3D printing' },
        { cName: 'PCB designing' },
        { cName: 'User Interface' }
    ]
    const navigate = useNavigate();
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        setSelected(pageNo);
    }, [])
    const componentSelected = sessionStorage.getItem('componentdetails') ? JSON.parse(sessionStorage.getItem('componentdetails')).component : 'Nothing';
    const [pcbSelected, setPcbSelected] = useState(false)

  

    

    //0: sessionStorage.getItem('personaldetails') ? true : false,
    //         1: sessionStorage.getItem('componentdetails') ? true : false,
    //         2: sessionStorage.getItem('selectiondetails') ? true : false,
    //         3: sessionStorage.getItem('devicedetailsselect') ? true : false,
    //         4: sessionStorage.getItem('enclosuredesigndetails') ? true : false,
    //         5: sessionStorage.getItem('pcbdetailssaved') ? true : false,
    //         6: sessionStorage.getItem('uiselected') ? true : false,
    return (
        <>
            <div style={{ marginTop: "2rem", marginBottom: "2rem", color: 'red', backgroundColor: 'red' }}>
                <Row className="nav-bar" style={{ display: 'flex', minHeight: '50px' }} >
                    {/* fgf */}

                    {sessionStorage.getItem('personaldetails') &&
                        <Col
                            sm={12}
                            md={12 / 8}
                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(0);
                                    navigate('/');
                                }

                                }


                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 0 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 0 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[0].cName}
                                    </p>}
                            </div>
                        </Col>}

                    {sessionStorage.getItem('componentdetails') &&
                        <Col
                            sm={12}
                            md={12 / 4}

                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(1)
                                    navigate('/componentselectpage');
                                }
                                }
                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 1 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 1 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[1].cName}
                                    </p>}
                            </div>
                        </Col>}

                    {sessionStorage.getItem('selectiondetails') &&
                        <Col
                            sm={12}
                            md={12 / 8}

                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(2);
                                    navigate('/productinformationselectoption');
                                }
                                }
                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 2 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 2 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[2].cName}
                                    </p>}
                            </div>
                        </Col>
                    }
                    {sessionStorage.getItem('devicedetailsselect') &&
                        <Col
                            sm={12}
                            md={12 / 8}

                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(3);
                                    if (sessionStorage.getItem('devicedetailsselect') === 'upload') {
                                        navigate('/uploadfilesdirectlypage');
                                    } else {
                                        if (componentSelected === 'Complete System') {
                                            navigate('/completesystemoverview');
                                        } else if (componentSelected === 'IOT device') {
                                            navigate('/iotdevicesection_1');
                                        } else if (componentSelected === 'PCB design') {
                                            navigate('/pcbdesignpage_1');
                                        }
                                    }
                                }
                                }
                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 3 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {

                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 3 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[3].cName}
                                    </p>}
                            </div>
                        </Col>}

                    {sessionStorage.getItem('enclosuredesigndetails') && sessionStorage.getItem("iotchoices_1") &&
                        <Col
                            sm={12}
                            md={12 / 8}

                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(4);
                                    if (sessionStorage.getItem('enclosuredesigndetails') && sessionStorage.getItem("iotchoices_1")) {
                                        navigate('/enclosuredesignpage');
                                    }
                                }
                                }
                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 4 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 4 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[4].cName}
                                    </p>}
                            </div>
                        </Col>}

                    {sessionStorage.getItem("pcbdetailssaved") && sessionStorage.getItem("iotchoices_1") &&
                        <Col
                            sm={12}
                            md={12 / 8}

                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(5);

                                    if(sessionStorage.getItem("pcbdetailssaved") && sessionStorage.getItem("iotchoices_1")){
                                        navigate('/pcbdesignpage_1');
                                    }
                                    

                                }
                                }
                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 5 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 5 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[5].cName}
                                    </p>}
                            </div>
                        </Col>}

                    {sessionStorage.getItem('uiselected') &&
                        <Col
                            sm={12}
                            md={12 / 8}

                            style={{
                                padding: '0',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setSelected(6);

                                    if (sessionStorage.getItem('uiselected') === 'web') {
                                        navigate('/webapplicationpage');
                                    } else if (sessionStorage.getItem('uiselected') === 'td') {
                                        navigate('/touchdisplaypage');
                                    } else if (sessionStorage.getItem('uiselected') === 'ntd') {
                                        navigate('/nontouchdisplaypage');
                                    } else if (sessionStorage.getItem('uiselected') === 'mobile') {
                                        navigate('/mobileapppage');
                                    }
                                }
                                }
                                style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: 6 === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: pageNo === selected || selected === 0 || selected === 6 ? '6px 6px 6px 6px ' : '0',
                                }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: 6 === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {components[6].cName}
                                    </p>}
                            </div>
                        </Col>}

                </Row>
            </div>
            <br />
        </>
    );
}

export default NavBar;



// const [componentSelected, setComponentSelected] = useState({});

// useEffect(() => {
//     const initialComponentSelected = {
//         0: sessionStorage.getItem('personaldetails') ? true : false,
//         1: sessionStorage.getItem('componentdetails') ? true : false,
//         2: sessionStorage.getItem('selectiondetails') ? true : false,
//         3: sessionStorage.getItem('devicedetailsselect') ? true : false,
//         4: sessionStorage.getItem('enclosuredesigndetails') ? true : false,
//         5: sessionStorage.getItem('pcbdetailssaved') ? true : false,
//         6: sessionStorage.getItem('uiselected') ? true : false,
//     };
//     const filteredComponentSelected = Object.fromEntries(
//         Object.entries(initialComponentSelected).filter(([key, value]) => value)
//     );
//     setComponentSelected(filteredComponentSelected);
//     console.log(initialComponentSelected);

// }, [sessionStorage.getItem('personaldetails'), sessionStorage.getItem('componentdetails'), sessionStorage.getItem('selectiondetails'), sessionStorage.getItem('devicedetailsselect'), sessionStorage.getItem('enclosuredesigndetails'), sessionStorage.getItem('pcbdetailssaved'), sessionStorage.getItem('uiselected')]);



{/* {components.map((component, index) => (
                        <Col
                            sm={12}
                            md={index === 1 ? 12 / 4 : 12 / 8}
                            key={index}
                            style={{
                                padding: '0',
                            }}
                        >
                            <div style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                backgroundColor: index === pageNo ? 'rgba(241, 239, 249, 1)' : 'white',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: pageNo === index || index === 0 || index === 6 ? '6px 6px 6px 6px ' : '0',
                            }}
                            >
                                {
                                    // componentSelected[index]===true &&
                                    <p className="text" style={{
                                        fontFamily: 'poppins',
                                        fontWeight: '600',
                                        color: index === pageNo ? 'rgba(156, 136, 255, 1)' : 'rgba(71, 85, 105, 1)'
                                    }}>
                                        {component.cName}
                                    </p>}
                            </div>
                        </Col>
                    ),)
                    } */}