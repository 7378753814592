import { Container, Col, Row } from "react-grid-system";
import NavBar from "./NavBar";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref, set, update } from "firebase/database";
import { database } from "../firebase";
import helpIcon from '../assets/help.svg'
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';
import "./Bfutton.css";

const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function EnclosureDesignPage() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        sessionStorage.setItem('startedComponentSelected', 'started')
    }, [])

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const [finishButtonText,setFinishButtonText]=useState('Next');

    useEffect(()=>{
        const enclosureSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).enclosure;
        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));
        if (componentSelected === 'Complete System') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";

            if (pcbSelected === 'Yes') {
               
            } else if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                   
                } else if (interfaceSelected === 'Web App') {
                   
                } else if (interfaceSelected === 'Touch Display') {
                    
                } else if (interfaceSelected === 'Non-Touch Display') {
                    
                }
            } else {
                setFinishButtonText('Finish');
            }

        } else {
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));

            if (pcbSelected === 'Yes') {
                
            } else if (interfaceSelected === 'Mobile App') {
               
            } else if (interfaceSelected === 'Web App') {
                
            } else if (interfaceSelected === 'Touch Display') {
               
            } else if (interfaceSelected === 'Non-Touch Display') {
               
            } else {
                setFinishButtonText("Finish");
            }

        }
    },[]);

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2)
        };

        emailjs.send(
            SERVICE_ID,
            TEMPLATE_ID,
            emailParams,
            PUBLIC_KEY
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const [changesAdded, setChangesAdded] = useState(false);
    const [designDetails, setDesignDetails] = useState(() => {
        const savedDesignDetails = sessionStorage.getItem('enclosuredesigndetails');
        return savedDesignDetails ? JSON.parse(savedDesignDetails) : {
            enclosure_size: '',
            enclosure_shape: '',
            print_color3D: '',
            layer_size3D: '',
            thread_size: '',
            bolts_details: '',
            port_placements: '',
            other_details: '',
            printing_type: 'FDM 3D Print',
            material: 'PLA',
        }
    });

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.clear();

        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    useEffect(() => {
        sessionStorage.setItem('enclosuredesigndetails', JSON.stringify(designDetails));
    }, [designDetails]);

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setDesignDetails((prev) => ({ ...prev, [name]: value }));

        if (value === 'FDM 3D Print' || value === 'SLA 3D Print') {
            setDesignDetails((prev) => ({ ...prev, material: '' }));
        }
    }

    const [error, setError] = useState(false);

    function handleClickNext() {
        if (designDetails.material === '') {
            setError(true);
            return;
        } else {
            setError(false);
        }

        console.log(JSON.parse(sessionStorage.getItem('enclosuredesigndetails')));

        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));



        if (componentSelected === 'Complete System') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
            let iot7 = sessionStorage.getItem('batterydetails');

            if (pcbSelected === 'Yes') {

                navigate('/pcbdesignpage_1');
            } else if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {

                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                    navigate('/mobileapppage');
                } else if (interfaceSelected === 'Web App') {
                    navigate('/webapplicationpage');
                } else if (interfaceSelected === 'Touch Display') {
                    navigate('/touchdisplaypage');
                } else if (interfaceSelected === 'Non-Touch Display') {
                    navigate('/nontouchdisplaypage');
                }
            } else {

                let finalIotObject = {}
                if (iot7) {
                    iot7 = JSON.parse(iot7);
                    finalIotObject = {
                        iot_page_1: iot1,
                        iot_page_2: iot2,
                        iot_page_3: iot3,
                        iot_page_4: iot4,
                        iot_page_5: iot5,
                        ML_Energyconservation_Battery: iot6,
                        battery_details: iot7,
                        enclosure_details: designDetails
                    };
                } else {
                    finalIotObject = {
                        iot_page_1: iot1,
                        iot_page_2: iot2,
                        iot_page_3: iot3,
                        iot_page_4: iot4,
                        iot_page_5: iot5,
                        ML_Energyconservation_Battery: iot6,
                        enclosure_details: designDetails
                    };
                }

                let permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
                const iotDeviceKeyName = `iot_device_${permanentdetails.numberOfIotDevices}`
                let detailsSaved = {};

                let dateNow = 'date';
                if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                    dateNow = Date.now();
                    sessionStorage.setItem('permanentdetails', JSON.stringify(
                        {
                            ...permanentdetails,
                            dateNow: dateNow
                        }
                    ));
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    detailsSaved = {
                        personalDetails: personDetailsSaved,
                        component: componentSelected,
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    set(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === '1' || permanentdetails.numberOfIotDevices === '1') {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();
                        } else {
                            permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );
                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('batterydetails');
                            sessionStorage.removeItem('enclosuredesigndetails');
                            sessionStorage.removeItem('uiselected');
                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })
                } else {
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    dateNow = permanentdetails.dateNow;
                    detailsSaved = {
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    update(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === 1 || permanentdetails.numberOfIotDevices === 1) {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();
                        } else {
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );
                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('batterydetails');
                            sessionStorage.removeItem('enclosuredesigndetails');
                            sessionStorage.removeItem('uiselected');
                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })
                }




            }
        } else {
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
            let iot7 = sessionStorage.getItem('batterydetails');

            if (pcbSelected === 'Yes') {
                navigate('/pcbdesignpage_1');
            } else if (interfaceSelected === 'Mobile App') {
                navigate('/mobileapppage');
            } else if (interfaceSelected === 'Web App') {
                navigate('/webapplicationpage');
            } else if (interfaceSelected === 'Touch Display') {
                navigate('/touchdisplaypage');
            } else if (interfaceSelected === 'Non-Touch Display') {
                navigate('/nontouchdisplaypage');
            } else {
                let finalIotObject = {}
                if (iot7) {
                    iot7 = JSON.parse(iot7);
                    finalIotObject = {
                        iot_page_1: iot1,
                        iot_page_2: iot2,
                        iot_page_3: iot3,
                        iot_page_4: iot4,
                        iot_page_5: iot5,
                        ML_Energyconservation_Battery: iot6,
                        battery_details: iot7,
                        enclosure_details: designDetails
                    };
                } else {
                    finalIotObject = {
                        iot_page_1: iot1,
                        iot_page_2: iot2,
                        iot_page_3: iot3,
                        iot_page_4: iot4,
                        iot_page_5: iot5,
                        ML_Energyconservation_Battery: iot6,
                        enclosure_details: designDetails
                    };
                }

                const detailsSaved = {
                    personalDetails: personDetailsSaved,
                    component: componentSelected,
                    details: finalIotObject
                }
                const dbRef = ref(database, `${Date.now()}`);
                set(dbRef, detailsSaved).then(() => {
                    console.log("Saved to database");
                }).catch((error) => {
                    console.error("Error saving in database: ", error);
                })
                sendEmail(detailsSaved);
                sessionStorage.clear();
                setChangesAdded(true);
                handleClickOpen();
            }
        }



    }


    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={4} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '1rem',
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={6} >

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>Enclosure Design</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            paddingTop: '5px',
                                            marginRight: '40px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred enclosure size
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="enclosure_size"
                                        placeholder="Enter Size"
                                        className="inputBox"
                                        value={designDetails.enclosure_size}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '1rem',
                                            borderRadius: "5px",

                                            width: "30%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, enclosure_size: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            paddingTop: '5px',
                                            marginRight: '23px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred enclosure shape
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        className="inputBox"
                                        name="enclosure_shape"
                                        value={designDetails.enclosure_shape}
                                        placeholder="Enter Shape"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '1rem',
                                            borderRadius: "5px",

                                            width: "30%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, enclosure_shape: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            paddingTop: '5px',
                                            marginRight: '45px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred 3D print color
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="print_color3D"
                                        value={designDetails.print_color3D}
                                        placeholder="Enter Color"
                                        className="inputBox"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '1rem',
                                            borderRadius: "5px",

                                            width: "30%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, print_color3D: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            paddingTop: '5px',
                                            marginRight: '3px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred layer size of 3D print
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="layer_size3D"
                                        value={designDetails.layer_size3D}
                                        placeholder="Enter Size"
                                        className="inputBox"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '1rem',
                                            borderRadius: "5px",

                                            width: "30%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, layer_size3D: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            marginRight: '60px',
                                            paddingTop: '5px',
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred thread size
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        className="inputBox"
                                        name="thread_size"
                                        placeholder="Enter Size"
                                        value={designDetails.thread_size}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginLeft: '1rem',
                                            borderRadius: "5px",

                                            width: "30%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, thread_size: e.target.value } }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",
                                            fontSize: "0.8rem",
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred no of bolts and preferred places
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="bolts_details"
                                        className="inputBox"
                                        placeholder="Enter number of bolts and places"
                                        value={designDetails.bolts_details}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '10',
                                            borderRadius: "5px",

                                            width: "70%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, bolts_details: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Any preferred output port placements
                                    </label>
                                    <br />
                                    <input
                                        className="inputBox"
                                        type="text"
                                        name="port_placements"
                                        value={designDetails.port_placements}
                                        placeholder="Enter port placement"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '10',
                                            borderRadius: "5px",

                                            width: "70%",
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, port_placements: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>

                    </Col>
                    <Col sm={12} md={6} >
                        <Row style={{ marginTop: '1rem' }} >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem', marginBottom: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Preferred places for buttons, switches, displays, indicators etc
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        name="other_details"
                                        className="textBoxIot2"
                                        value={designDetails.other_details}
                                        placeholder="Enter places for buttons, switches, displays, indicators etc"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "100%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, other_details: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginBottom: '10px' }} >
                                    <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                        Type of Printing Preferred <span style={{ color: "red" }}>*</span>
                                        <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                            <img className="plusImage" src={helpIcon} alt="help" style={{
                                                paddingLeft: '3px',
                                                cursor: 'pointer', verticalAlign: 'middle'
                                            }} /></a></span>
                                    </label>
                                    <div style={{ marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '30px' }}>
                                        {["FDM 3D Print", "SLA 3D Print"].map((printType3d) => (
                                            <label className="text" key={printType3d} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="radio"
                                                    value={printType3d}
                                                    name="printing_type"
                                                    checked={designDetails.printing_type === printType3d}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onChange={handleRadioChange}
                                                />
                                                {printType3d}
                                            </label>
                                        ))}
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                {designDetails.printing_type === 'FDM 3D Print' &&
                                    <div style={{ paddingBottom: '10px' }}>
                                        <label className="text" style={{ fontFamily: 'poppins', color: error ? "red" : "rgba(71, 85, 105, 1)" }}>
                                            What is the needed material?
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                                <img className="plusImage" src={helpIcon} alt="help" style={{
                                                    paddingLeft: '3px',
                                                    cursor: 'pointer', verticalAlign: 'middle'
                                                }} /></a></span>
                                        </label>
                                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {["PLA", "ABS", "PETG", "TPU", "Nylon", "PC", "HIPS", "ASA", "Other"].map((material) => (
                                                <label className="text" key={material} style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={material}
                                                        name="material"
                                                        checked={material === "Other" ? (designDetails.material !== "PLA" && designDetails.material !== "ABS" && designDetails.material !== "PETG" && designDetails.material !== "TPU" && designDetails.material !== "Nylon" && designDetails.material !== "PC" && designDetails.material !== "HIPS" && designDetails.material !== "ASA") : designDetails.material === material}
                                                        style={{ cursor: 'pointer' }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {material}
                                                </label>
                                            ))}
                                        </div>
                                        <div>
                                            {designDetails.material !== "PLA" && designDetails.material !== "ABS" && designDetails.material !== "PETG" && designDetails.material !== "TPU" && designDetails.material !== "Nylon" && designDetails.material !== "PC" && designDetails.material !== "HIPS" && designDetails.material !== "ASA" &&

                                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                                    <div style={{ marginTop: '0.5rem', paddingBottom: '10px' }}>
                                                        <input
                                                            type="text"
                                                            name="material"
                                                            placeholder="Enter needed material"
                                                            value={designDetails.material === "Other" ? "" : designDetails.material}
                                                            className="inputBox"
                                                            style={{
                                                                border: '1px solid rgba(203, 213, 225, 0.7)',
                                                                fontFamily: 'poppins',
                                                                padding: '3px 3px 3px 15px',
                                                                marginLeft: '0.3rem',
                                                                borderRadius: "5px",

                                                                width: "70%",
                                                            }}
                                                            onChange={(e) => (setDesignDetails((prev) => { return { ...prev, material: e.target.value } }))}
                                                        />
                                                    </div>
                                                </Col>}
                                        </div>
                                    </div>
                                }{designDetails.printing_type === 'SLA 3D Print' &&
                                    <div style={{ paddingBottom: '10px' }} >
                                        <label className="text" style={{ fontFamily: 'poppins', color: error ? "red" : "rgba(71, 85, 105, 1)" }}>
                                            What is the needed material?
                                            <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer">
                                                <img className="plusImage" src={helpIcon} alt="help" style={{
                                                    paddingLeft: '3px',
                                                    cursor: 'pointer', verticalAlign: 'middle'
                                                }} /></a></span>
                                        </label>
                                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                                            {["Standard Resins", "Tough Resins", "Flexible Resins", "High-Temperature Resins", "Castable Resins", "Dental and Medical Resins", "Ceramic-Filled Resins", "Other"].map((material) => (
                                                <label className="text" key={material} style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value={material}
                                                        name="material"
                                                        checked={material === "Other" ? (designDetails.material !== "Standard Resins" && designDetails.material !== "Tough Resins" && designDetails.material !== "Flexible Resins" && designDetails.material !== "High-Temperature Resins" && designDetails.material !== "Castable Resins" && designDetails.material !== "Dental and Medical Resins" && designDetails.material !== "Ceramic-Filled Resins") : designDetails.material === material}
                                                        style={{ cursor: 'pointer' }}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {material}
                                                </label>
                                            ))}
                                        </div>
                                        <div>
                                            {designDetails.material !== "Standard Resins" && designDetails.material !== "Tough Resins" && designDetails.material !== "Flexible Resins" && designDetails.material !== "High-Temperature Resins" && designDetails.material !== "Castable Resins" && designDetails.material !== "Dental and Medical Resins" && designDetails.material !== "Ceramic-Filled Resins" &&

                                                <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                                    <div style={{ marginTop: '0.5rem', paddingBottom: '10px' }}>
                                                        <input
                                                            type="text"
                                                            name="material"
                                                            placeholder="Enter needed material"
                                                            value={designDetails.material === "Other" ? "" : designDetails.material}
                                                            className="inputBox"
                                                            style={{
                                                                border: '1px solid rgba(203, 213, 225, 0.7)',
                                                                fontFamily: 'poppins',
                                                                padding: '3px 3px 3px 15px',
                                                                marginLeft: '0.3rem',
                                                                borderRadius: "5px",

                                                                width: "70%",
                                                            }}
                                                            onChange={(e) => (setDesignDetails((prev) => { return { ...prev, material: e.target.value } }))}
                                                        />
                                                    </div>
                                                </Col>}
                                        </div>
                                    </div>

                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                        <Button className="text" onClick={handleCancelClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }} > Cancel </Button>
                        <Button onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold', fontSize: '13px' }}> Back </Button>
                        <Button onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold', fontSize: '13px' }}> {finishButtonText} </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px',
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default EnclosureDesignPage;

